import { EventEmitter, Injectable, Output} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class MainService {
  checkType = 1;
  constructor(public http: HttpClient,public router:Router) { }
  apiUrl = environment.apiUrl;
  apiSpringUrl = environment.apiSpringUrl;
  apiToken = environment.apiToken;

  httpOptions = {
   headers: new HttpHeaders({ 
     'Content-Type': 'application/json',
     'ApiToken' : this.apiToken,
   })
 };

  // @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  AdminLog(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}admin_login`,data,this.httpOptions);
  }
  SeoLog(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}seo_login`,data,this.httpOptions);
  }
  allblogs():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}blogs`,this.httpOptions);
  }
  fetchPage(data):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}seoinfo?href=`+data,this.httpOptions);
  }
  fetchPageData(data):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}pagedata?href=`+data,this.httpOptions);
  }
  totalBlogs():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}total_blogs`,this.httpOptions);
  }
  totalSeoPage():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}totalSeoPage`,this.httpOptions);
  }
  addBlog(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}add_blog`,data,this.httpOptions);
  }
  addSeo(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}addseopage`,data,this.httpOptions);
  }
  // blogDetail(data):Observable<any>{
  //   return this.http.get<any>(`${this.apiUrl}blog_detail?blog_id=`+ data,this.httpOptions);
  // }
  blogDetail(data):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}blog_detail?href=`+ data,this.httpOptions);
  }
  seoPageDetail(data):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}seo/detail?href=`+ data,this.httpOptions);
  }
  editBlog(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}edit_blog`,data,this.httpOptions);
  }
  editSeoPage(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}seo/edit`,data,this.httpOptions);
  }
  statusChange(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}change_status`,data,this.httpOptions);
  }
  ContactForms(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}contact`,data,this.httpOptions);
  }
  EnquiryForms(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}enquiry`,data,this.httpOptions);
  }
  VerifyEnquiry(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}verify/number`,data,this.httpOptions);
  }
  stuAmbssadorForms(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}add_stu_abssdr`,data,this.httpOptions);
  }
  SubscribeForm(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}subscription`,data,this.httpOptions);
  }
  TotalSubscriber():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}subscriber_list`,this.httpOptions);
  }
  TotalContact():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}get_contact`,this.httpOptions);
  }
  totalPages():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}allPages`,this.httpOptions);
  }
  totalPagesContent():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}allPagesContent`,this.httpOptions);
  }
  aboutPagesContent():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}aboutPagesContent`,this.httpOptions);
  }
  addPage(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}add_page`,data,this.httpOptions);
  }
  addPagecontent(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}add_pagecontent`,data,this.httpOptions);
  }
  pageDetail(data):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}page_detail?href=`+ data,this.httpOptions);
  }
  pagecontentDetail(data):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}page_contentdetail?name=`+ data,this.httpOptions);
  }
  pagecontentABoutDetail(data):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}PagecontentAboutDetail?name=`+ data,this.httpOptions);
  }
  editPage(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}edit_page`,data,this.httpOptions);
  }
  editPagecontent(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}edit_pagecontent`,data,this.httpOptions);
  }
  EditAboutPagecontent(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}EditAboutPagecontent`,data,this.httpOptions);
  }
  deletePage(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}delete_page`,data,this.httpOptions);
  }
  SurveyForm(data):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}survey`,data,this.httpOptions);
  }
  TotalSurvey():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}survey_list`,this.httpOptions);
  }
  TotalAmbessador():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}all_ambessador`,this.httpOptions);
  }
  fileUpload(token, data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        //'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiSpringUrl}/kyc/txn/document/upload/student/a2form`,data,httpOptions)
  }
  Rates():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}get_rates`,this.httpOptions);
  }
  isLoggedIn(){
    if (sessionStorage.getItem('user') != undefined) {
     return true;
    }else if(sessionStorage.getItem('user') != null){ 
      return true;
    }
    else{
      return false;
    }
  }
LoggedIn(){
  if (sessionStorage.getItem('user') != undefined) {
    this.router.navigate(['']);
   }
  // //console.log(sessionStorage.getItem('user'))
}
}
