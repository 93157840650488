import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { MainService } from '../main.service';
import { NavigationEnd, Router, } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  Isloggin:boolean;
  userType: any;
  userToken: string;
  universityCode: string;
  userdCurrency: string;
  userdCurrencyName: string;

  constructor(public mainservice:MainService, public router: Router) { 
    if(window.location.href.includes('spjainpayments')){
      this.universityCode='SPJ';
      sessionStorage.setItem('univCode','SPJ');
    }else if(window.location.href.includes('studentcom')){
      this.universityCode='STUDCOM';
      sessionStorage.setItem('univCode','STUDCOM');
    }else if(window.location.href.includes('sophiya')){
      this.universityCode='SOPHIYA';
      sessionStorage.setItem('univCode','SOPHIYA');

    }else if(window.location.href.includes('atmc')){
      this.universityCode='ATMC';
      sessionStorage.setItem('univCode','ATMC');
    }
    else if(window.location.href.includes('canam')){
      this.universityCode='CANAM';
      sessionStorage.setItem('univCode','CANAM');
    }
    else if(window.location.href.includes('zengage')){
      this.universityCode='ZENGAGE';
      sessionStorage.setItem('univCode','ZENGAGE');
    }
    else if(window.location.href.includes('gradright')){
      this.universityCode='GRD';
      sessionStorage.setItem('univCode','GRD');
    }
    else if(window.location.href.includes('youroshc')){
      this.universityCode='YOUROSHC';
      sessionStorage.setItem('univCode','YOUROSHC');
    }
    else if(window.location.href.includes('UCB')){
      this.universityCode='UCB';
      sessionStorage.setItem('univCode','UCB');
    }
    else if(window.location.href.includes('cranfield')){
      this.universityCode='CRANFIELD';
      sessionStorage.setItem('univCode','CRANFIELD');
    }
    else if(window.location.pathname==='/' || window.location.pathname==='/ucb' ||window.location.pathname==='/crm-login' ||window.location.pathname==='/consultant-login'||window.location.pathname==='/admin'||window.location.pathname==='/super-admin' ||window.location.pathname.includes('admin/login')||window.location.pathname.includes('/seo/login')){
      sessionStorage.removeItem('univCode');
      this.universityCode=null;
    }
    else{
      this.universityCode = sessionStorage.getItem('univCode');
    }
    if(this.mainservice.isLoggedIn()){
      let data = JSON.parse(sessionStorage.getItem('user'));
      this.userType = data.userType;
        this.Isloggin = true;
    }else{
      this.Isloggin =false;
    }
  }

  ngOnInit() {
    this.router.events
      this.userToken = sessionStorage.getItem('userToken');
      if(this.userToken){
        this.Isloggin = true;
        // alert('hii')
      }
      else
      {
        // window.location.href='/';
      }
    
    ////console.log("userToken",this.userToken)
    $("[data-trigger]").on("click", function(){
      var trigger_id =  $(this).attr('data-trigger');
      $(trigger_id).toggleClass("show");
      $('body').toggleClass("offcanvas-active");
  });
  
  // close button 
  $(".btn-close").click(function(e){
      $(".navbar-collapse").removeClass("show"); 
      $("body").removeClass("offcanvas-active");
  });
  
  }
  logOut(){
    this.userdCurrency = localStorage.getItem('yourKey');
    this.userdCurrencyName = localStorage.getItem('yourKeyName');
    
    localStorage.removeItem('user');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('yourKey',this.userdCurrency);
    localStorage.setItem('yourKeyName',this.userdCurrencyName);
    if(this.userType == 'CRM_CREATOR')
    {
      this.router.navigate(['/crm-login'], { replaceUrl: true });
      window.location.href='/crm-login'; 
    }
    else if(this.userType == 'SUPER_USER')
    {
      this.router.navigate(['/super-admin'], { replaceUrl: true });
      window.location.href='/super-admin'; 
    }
    else if(this.userType == 'CONSULTANT')
    {
      this.router.navigate(['/consultant-login'], { replaceUrl: true });
      window.location.href='/consultant-login'; 
    }
    else if(this.userType == 'STUDENT')
    {
      this.router.navigate(['/home'], { replaceUrl: true });
      window.location.href='/'; 
    }
    else
    {
      this.router.navigate(['/home'], { replaceUrl: true });
      window.location.href='/'; 
    }
  }
  login()
  {
    window.location.href='/register'; 
  }

}
