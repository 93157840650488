// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepagemodalbackground {
    background: #000000 0% 0% no-repeat padding-box;
    background-color: rgb(105 162 212 / 94%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}
.homepagemodalbox {
    background: #f2eded63 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 24px #000000A3;
    border-radius: 30px;
    opacity: 1!important;
}
.countdown-container {
    margin-top: 100px;
    position: fixed;
    top: 10px; /* Adjust top position as needed */
    right: 10px; /* Adjust right position as needed */
    color: white;
    padding: 10px;
    z-index: 999;
    text-align: right;
}
.countdown-clock {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}


@media (max-width: 767px){

    .countdown-container {
        max-width: 134px;
        margin-top: 54px;
        position: fixed;
        top: 10px; /* Adjust top position as needed */
        right: 10px; /* Adjust right position as needed */
        color: white;
        padding: 10px;
        z-index: 999;
        text-align: right;
        font-size: 9px;
     }
    .countdown-clock {
     display: block;
            }
        
}`, "",{"version":3,"sources":["webpack://./src/app/payment-true/payment-true.component.css"],"names":[],"mappings":"AAAA;IACI,+CAA+C;IAC/C,wCAAwC;IACxC,2BAA2B;IAC3B,mCAAmC;AACvC;AACA;IACI,iDAAiD;IACjD,kCAAkC;IAClC,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,SAAS,EAAE,kCAAkC;IAC7C,WAAW,EAAE,oCAAoC;IACjD,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;;AAGA;;IAEI;QACI,gBAAgB;QAChB,gBAAgB;QAChB,eAAe;QACf,SAAS,EAAE,kCAAkC;QAC7C,WAAW,EAAE,oCAAoC;QACjD,YAAY;QACZ,aAAa;QACb,YAAY;QACZ,iBAAiB;QACjB,cAAc;KACjB;IACD;KACC,cAAc;YACP;;AAEZ","sourcesContent":[".homepagemodalbackground {\n    background: #000000 0% 0% no-repeat padding-box;\n    background-color: rgb(105 162 212 / 94%);\n    backdrop-filter: blur(10px);\n    -webkit-backdrop-filter: blur(10px);\n}\n.homepagemodalbox {\n    background: #f2eded63 0% 0% no-repeat padding-box;\n    box-shadow: 0px 8px 24px #000000A3;\n    border-radius: 30px;\n    opacity: 1!important;\n}\n.countdown-container {\n    margin-top: 100px;\n    position: fixed;\n    top: 10px; /* Adjust top position as needed */\n    right: 10px; /* Adjust right position as needed */\n    color: white;\n    padding: 10px;\n    z-index: 999;\n    text-align: right;\n}\n.countdown-clock {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n\n@media (max-width: 767px){\n\n    .countdown-container {\n        max-width: 134px;\n        margin-top: 54px;\n        position: fixed;\n        top: 10px; /* Adjust top position as needed */\n        right: 10px; /* Adjust right position as needed */\n        color: white;\n        padding: 10px;\n        z-index: 999;\n        text-align: right;\n        font-size: 9px;\n     }\n    .countdown-clock {\n     display: block;\n            }\n        \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
