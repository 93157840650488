// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section{
    margin-top: 80px;
    margin-bottom: 60px;
}
@media screen and (max-width:600px){
    section{
        margin-top: 65px;
    }
}  `, "",{"version":3,"sources":["webpack://./src/app/privacy-policy/privacy-policy.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["section{\n    margin-top: 80px;\n    margin-bottom: 60px;\n}\n@media screen and (max-width:600px){\n    section{\n        margin-top: 65px;\n    }\n}  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
