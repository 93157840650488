import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/dashboard.service';
import { NotifyService } from 'src/app/notify.service';
import $ from 'jquery';
import { MainService } from 'src/app/main.service';
import { interval } from 'rxjs';

export function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.css']
})
export class FrontComponent implements OnInit {
  Inputmode: number=.0134;
  InputAmount:number=500;
  OutputAmount: number=this.Inputmode*this.InputAmount;
  Outputmode: number=1;
  exchange:boolean=true;
  adminToken: any='';
  Rates: any=[];
  stuForm: FormGroup;
  isSpinner:boolean=false;
  dataId: any;
  verifyToken: any;
  emailVerification: any;
  stuLoginForm: FormGroup;
  userToken: any;
  user: any='';
  verifyForm: boolean=false;
  resendDisable: boolean=false;
  timeLeft: number = 10;
  interval;
  fieldTextType: boolean;
  ConfirmfieldTextType:boolean;
  loginfieldTextType:boolean;
  showUnit: any='USD';
  constructor(public element:ElementRef, public router:Router,private renderer: Renderer2,public _dash:DashboardService,public notificationService:NotifyService,private formBuilder : FormBuilder,public main:MainService) {
    this.stuForm = this.formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      fname:new FormControl('',Validators.required),
      lname:new FormControl('',Validators.required),
      number:new FormControl('',Validators.required),
      type:new FormControl('STUDENT',Validators.required),
      password:new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirm_password:new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, { 
      validator: ConfirmedValidator('password', 'confirm_password')
    }
    );
    this.stuLoginForm = this.formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      password:new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
   }
   FieldType() {
    this.fieldTextType = !this.fieldTextType;
  }
  confirmFieldType() {
    this.ConfirmfieldTextType = !this.ConfirmfieldTextType;
  }
  loginFieldType(){
    this.loginfieldTextType = !this.loginfieldTextType;
  }

  amount(val){
    //console.log(val)
    this.InputAmount = val;
    this.calculation();
  }
  modeInput(val){
    this.Inputmode =val;
    let x = this.Rates.filter(x=>x.rate == val);
    this.showUnit = x[0].currency_code;
    //console.log("sss",val,this.showUnit)
    this.calculation();
  }
  calculation(){
    let x:any = Number(this.InputAmount*(this.Inputmode ));
    this.OutputAmount = Number(parseFloat(x).toFixed(3));
  }
  // getRates(){
  //   this._dash.getRates(this.adminToken).subscribe((res:Response)=>{
  //     if(res['success']){
  //       let rate =res['data']['rates'];
  //       this.Rates = Object.entries(rate)
  //     }
  //   })
  // }
  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.notificationService.showSuccess("Content Copied To clipboard", "Copied !!");
  }
  submit(stuForm){
    this.isSpinner =true;
    let reg_data ={
      "email": stuForm.email,
      "firstName": stuForm.fname,
      "lastName": stuForm.lname,
      "secret": stuForm.password,
     "mobile":stuForm.number,
      "secretType": "PASSWORD",
      "userType": 'STUDENT',
      "confirmSecret": stuForm.confirm_password,
      "middleName": "API"
    }
    //console.log("re",reg_data);
    this._dash.getToken().subscribe((token:Response)=>{
      if(token['success']){
        this._dash.getRegister(reg_data,token['data'].token).subscribe((res:Response)=>{
          if(res['success']){
            let data ={ "loginId": stuForm.email}
              this.verifyForm = true;
                this.dataId =res['data'].dataId;
                this.verifyToken = res['data'].token;
                this.emailVerification = stuForm.email;
                this.isSpinner =false;
            }
        },
        (error) => {
          this.notificationService.showError(error.error['errors'][0] , "Error !!" , 5000);
         // this.notificationService.showError(error.error['message'][0] , "Error !!",4000);
          this.isSpinner =false;
         })
      }
    },
    (error) => {
      this.notificationService.showError(error.error['message'] , "Error !!",3000);
      this.isSpinner =false;
     });
  }
  Login(stuLoginForm){
    this.isSpinner =true;
    let data ={
      "email": stuLoginForm.email,
      "secret": stuLoginForm.password,
      "userType": 'STUDENT',
    }
    //console.log("re",data);
    this._dash.getToken().subscribe((token:Response)=>{
      if(token['success']){
        this._dash.getLogin(data,token['data'].token).subscribe((res:Response)=>{
          if(res['success']){
            let studentToken = res['data'].token;
           sessionStorage.setItem('studentToken',studentToken);
           this._dash.getUser(this.userToken).subscribe((res:Response)=>{
            if(res['success']){
              sessionStorage.setItem('userType',res['data'].userType);
              //sessionStorage.setItem('user',JSON.stringify(res['data']));
            }
           });
           $('.close').click(); 
           this.notificationService.showSuccess("Logged In Successfully" , "Success !!");
           window.location.href="payer/start"
          // this.router.navigate(['payer/start']);

          }
          this.isSpinner =false;
        },
        (error) => {
          // //console.log("gvg",error.error['message']);
          this.notificationService.showError(error.error['errors'] , "Error !!" , 4000);
          this.notificationService.showError(error.error['message'] , "Error !!",4000);
          this.isSpinner =false;
          //$('.close').click(); 
         }
         
        )}
    });
  }
  resend(){
    this.timeLeft = 10;
    this.isSpinner =true;
    let data ={ "loginId": this.emailVerification}
    this._dash.getToken().subscribe((token:Response)=>{
      this._dash.sendVerifyLink(data,token['data'].token).subscribe((verify:Response)=>{
        if(verify['success']){
          this.notificationService.showSuccess(verify['message'], "Success !!");
          this.verifyForm = true;
          this.dataId =verify['data'].dataId;
          this.verifyToken = verify['data'].token;
          this.interval = setInterval(() => {
            if(this.timeLeft > 0) {
              this.timeLeft--;
              this.resendDisable = true;
            } else {
              this.resendDisable = false;
            }
          },1000);
        }
        this.isSpinner =false;
      },
      (error) => {
        // //console.log("gvg",error.error['message']);
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
        this.isSpinner =false;
       });
    });

  }
  verifyUser(otp){
    this.isSpinner =true;
    var data = {
      "verificationCode":otp.value,
      "id":this.dataId
    }
    //console.log("ss",data,this.verifyToken)
    this._dash.verification(data,this.verifyToken ).subscribe((res:Response)=>{
      if(res['success']){
        $('.close').click();
        this.stuForm.reset();
        this.verifyForm = false;
        this.notificationService.showSuccess(res['message'], "Success !!");
        this.notificationService.showSuccess("Your account created successfully , Please Login", "Success !!");
       // window.location.reload();
      }
      this.isSpinner =false;
    },
    (error) => {
      // //console.log("gvg",error.error['message']);
      this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
      this.notificationService.showError(error.error['message'] , "Error !!",3000);
      this.isSpinner =false;
     })
  }
  ngAfterViewInit(){
   // this.getRates();
   this.Rate();
  }
  Rate(){
    this.main.Rates().subscribe((res:Response)=>{
      if(res['success']){
        let rate =res['rates'];
        this.Rates = rate;
        //console.log(this.Rates);
        let x = this.Rates.filter(x=>x.currency_code == 'USD');
        this.Inputmode =  x[0].rate;
        this.calculation();
      }
    })
  }
ngOnInit() {
  let User = JSON.parse(sessionStorage.getItem('user'));
    if(User!=null){
      this.user = this.user.email;
    }
    // let subscription = interval(1000)
    //        .subscribe(x => { //console.log(x) });
}
}
