// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section{
    margin-top: 120px;
    margin-bottom: 80px;
}
.form-control{
    height: 60px;
    border-radius: 12px;
    border: 1px solid #215D88;
 }
 h1{
    font-size: 24px;
    color: #215D88;
    text-shadow: 0 0 3px #215D88, 0 0 5px #ffff;
    font-weight: 600;
 }
 button{
    border-radius: 13px;
    border: 1px solid #215D88;
    padding: 8px 12px; 
    background: #215D88 0% 0% no-repeat padding-box;
 }`, "",{"version":3,"sources":["webpack://./src/app/seo-login/seo-login.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;CAC5B;CACA;IACG,eAAe;IACf,cAAc;IACd,2CAA2C;IAC3C,gBAAgB;CACnB;CACA;IACG,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,+CAA+C;CAClD","sourcesContent":["section{\n    margin-top: 120px;\n    margin-bottom: 80px;\n}\n.form-control{\n    height: 60px;\n    border-radius: 12px;\n    border: 1px solid #215D88;\n }\n h1{\n    font-size: 24px;\n    color: #215D88;\n    text-shadow: 0 0 3px #215D88, 0 0 5px #ffff;\n    font-weight: 600;\n }\n button{\n    border-radius: 13px;\n    border: 1px solid #215D88;\n    padding: 8px 12px; \n    background: #215D88 0% 0% no-repeat padding-box;\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
