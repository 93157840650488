import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  subscribeForm: any;
  showModal: boolean =false;
  fillvar:any='';
  constructor(public router:Router, public mainservice:MainService,private formBuilder : FormBuilder , private notificationService:NotifyService) { 
    this.subscribeForm = this.formBuilder.group({
      email: new FormControl('' ,[Validators.required,Validators.email]),
    });
  }
  // newsPopup(){
  //   this.showModal = true;
  // }
  // hide(){
  //   this.showModal =false;
  // }
  submitEmail(val){
    var submitdata = {
      'email': val.value
    }
    //console.log(submitdata);
    this.mainservice.SubscribeForm(submitdata).subscribe((res:Response)=>{
      if(res['success']){
        this.notificationService.showSuccess("You have successfully subscribed to the Newsletter", "Success !!");
        this.subscribeForm.reset();
        //this.hide();
      }else{
        this.notificationService.showError(res['error']['email'], "Failure !!",'');
      }
    });
  }
  // submit(subscribeForm){
  //   var submitdata = {
  //     'email': subscribeForm.email,
  //   }
  //   //console.log(submitdata);
  //   this.mainservice.SubscribeForm(submitdata).subscribe((res:Response)=>{
  //     if(res['success']){
  //       this.notificationService.showSuccess("You have successfully subscribed to the Newsletter", "Success !!");
  //       //this.subscribeForm.reset();
        
  //       //this.hide();
  //     }else{
  //       this.notificationService.showError(res['message'], "Failure !!",'');
  //     }
  //   });
  // }
  ngOnInit() {
  }

}
