import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConsultantListComponent } from '../maindash/student/consultant-list/consultant-list.component';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-consultant-login',
  templateUrl: './consultant-login.component.html',
  styleUrls: ['./consultant-login.component.css']
})
export class ConsultantLoginComponent implements OnInit {

  loginForm: FormGroup;
  admin: any;
  submitdata: any;
  registerData: any;
  verifyForm: boolean=false;
  verifyToken: any;
  dataId: any;
  userToken: any;
  emailVerification: any='';
  isSpinner:boolean=false;
  remember: boolean=true;
  interval;
  garbage: any
  Token: string;
  timeLeft: number;
  resendDisable: boolean=false;
  fieldTextType: boolean;
  ConfirmfieldTextType:boolean;
  loginfieldTextType:boolean;
  submitData: any
  constructor(public router:Router,private formBuilder : FormBuilder,public _dash:DashboardService,private route: ActivatedRoute,
    public mainservice:MainService, private notificationService:NotifyService,private cookieService: CookieService,private spinner: NgxSpinnerService ) {
    this.loginForm=formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      password:new FormControl('', [Validators.required, Validators.minLength(8),Validators.maxLength(32)]),
    });
   }

    loginFieldType(){
      this.loginfieldTextType = !this.loginfieldTextType;
    }

    checkForData(loginForm:any, data: boolean){
      return this._dash.checkForUserType(btoa(loginForm.email), this.Token).pipe(
        switchMap((result: Response) => {
          let valueData = result['data'];
          return of({
            "email": loginForm.email,
            "secret": loginForm.password,
          });
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
    onSubmit(loginForm) {
      this.spinner.show()
      var data = true;
      let submitdata;
      this.checkForData(loginForm, data).pipe(
        switchMap((data) => {
          submitdata = data;
          return this._dash.getToken();
        }),
        switchMap((token: Response) => {
          if (token['success']) {
            return this._dash.getLoginConsultant(submitdata, token['data'].token);
          } else {
            return throwError('Token retrieval failed');
          }
        })
      ).subscribe(
        (res: Response) => {
          this.spinner.hide()
          if (res['success']) {
            this.userToken = res['data'].token;
            sessionStorage.setItem('userToken', this.userToken);
            this._dash.getUser(this.userToken).subscribe((userRes: Response) => {
              if (userRes['success']) {
                sessionStorage.setItem('user', JSON.stringify(userRes['data']));
                this.notificationService.showSuccess("Logged In Successfully", "Success !!");
                this.router.navigate(['maindash/exchange']);
                if (this.remember) {
                  this.cookieService.set('U_email', loginForm.email);
                  this.cookieService.set('U_pass', loginForm.password);
                }
              } else {
                this.notificationService.showError(userRes['message'], "Error !!", 5000);
              }
            });
          } else {
            this.notificationService.showError(res['message'], "Error !!", 5000);
          }
        },
        (error) => {
          this.spinner.hide()
          if (error.error['errors']) {
            this.notificationService.showError(error.error['errors'], "Error !!", 3000);
          }
          if (error.error['message']) {
            this.notificationService.showError(error.error['message'], "Error !!", 3000);
          }
        }
      );
    }

  rememberMe(val){
    this.remember = val;
  }
  ngOnInit() {

    this._dash.getToken().subscribe((token:Response)=>{
      this.Token = token['data'].token
    })

    this.mainservice.LoggedIn();
    if(this.cookieService.get('U_email')){
      this.loginForm.controls['email'].setValue(this.cookieService.get('U_email'));
      this.loginForm.controls['type'].setValue(this.cookieService.get('U_type'));
      this.loginForm.controls['password'].setValue(this.cookieService.get('U_pass'));
    }

  }
}

