import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
@Component({
  selector: 'app-waitlist',
  templateUrl: './waitlist.component.html',
  styleUrls: ['./waitlist.component.css']
})
export class WaitlistComponent implements OnInit {
  WaitListForm: FormGroup;
  promoApplied: boolean=false;

  constructor(public router:Router,private formBuilder : FormBuilder,public mainservice:MainService, private notificationService:NotifyService) {

    this.WaitListForm=formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      name:new FormControl('',Validators.required),
      otp:new FormControl(''),
      collage:new FormControl('',Validators.required),
      mobile:new FormControl('', [Validators.required, Validators.pattern("^(([0-9]{2,3})|0)?[0-9]{10}$")]),
      code:new FormControl(''),
    });

   }
    get lf() { return this.WaitListForm.controls; }
  promo(){
    this.promoApplied = true;
    this.WaitListForm.controls['code'].setValue('PEARSON CONCLAVE');
    // this.WaitListForm.controls['code'].disable();
  }
  onSubmit(WaitListForm){
    var submitdata={
      'email':WaitListForm.email, 
      'name':WaitListForm.name,
      'collage':WaitListForm.collage,
      'mobile':WaitListForm.mobile,
      'otp':WaitListForm.otp,
      'code':WaitListForm.code,
      'type':'0'
  }
  //console.log(submitdata);
    this.mainservice.SurveyForm(submitdata).subscribe((res:Response) =>{
      if(res['success']){
        this.notificationService.showSuccess(res['message'], "Success !!");
        this.WaitListForm.reset();
        //this.WaitListForm.controls['type'].setValue('0');
        //this.hide();
      }else{
        this.notificationService.showError(res['message'], "Failure !!",'');
      }
    });
  }
  ngOnInit() {
  }

}
