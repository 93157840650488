import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { CKEditorModule } from 'ckeditor4-angular';
import { AboutComponent } from './about/about.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BlogsComponent } from './blogs/blogs.component';
import { ReadmorePipe } from './readmore.pipe';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SurveyComponent } from './survey/survey.component';
import { WaitlistComponent } from './waitlist/waitlist.component';
import { CommunityDetailComponent } from './community-detail/community-detail.component';
import {MatNativeDateModule} from '@angular/material/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { FrontComponent } from './studentDashboard/front/front.component';
import { StartNowComponent } from './studentDashboard/start-now/start-now.component';
import { AccountInfoComponent } from './studentDashboard/account-info/account-info.component';
import { ThankComponent } from './studentDashboard/thank/thank.component';
import { CookieService } from 'ngx-cookie-service';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { RegisterComponent } from './register/register.component';
import { AdminstrationLoginComponent } from './adminstration-login/adminstration-login.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { AustraliaFlowComponent } from './australia-flow/australia-flow.component';
import { BookRateComponent } from './book-rate/book-rate.component';
import { ConsultantLoginComponent } from './consultant-login/consultant-login.component';
import { ConsultantRegisterComponent } from './consultant-register/consultant-register.component';
import { CrmRegisterComponent } from './crm-register/crm-register.component';

import { CrmLoginComponent } from './crm-login/crm-login.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { SpjainHomeComponent } from './spjain-home/spjain-home.component';
// component create for meta tags uploading from the project
import { SeoLoginComponent } from './seo-login/seo-login.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component'; 
import { ChartComponent } from './chart/chart.component';
import { StudentcomHomeComponent } from './studentcom-home/studentcom-home.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SophiyaHomeComponent } from './sophiya-home/sophiya-home.component';
import { AtmcHomeComponent } from './atmc-home/atmc-home.component';
import { ZengageHomeComponent } from './zengage-home/zengage-home.component';
import { CanomHomeComponent } from './canom-home/canom-home.component';
import { ZengagehomeComponent } from './zengagehome/zengagehome.component';
import { GradRightComponent } from './grad-right/grad-right.component';
import { YouroshcHomeComponent } from './youroshc-home/youroshc-home.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentTrueComponent } from './payment-true/payment-true.component';
import { PaymentFalseComponent } from './payment-false/payment-false.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UcbHomeComponent } from './ucb-home/ucb-home.component';
import { CranfieldHomeComponent } from './cranfield-home/cranfield-home.component';
// component end for meta tags uploading from the project



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    BlogDetailComponent,
    AdminLoginComponent,
    ResetpasswordComponent,
    AboutComponent,
    NotFoundComponent,
    BlogsComponent,
    ReadmorePipe,
    PrivacyPolicyComponent,
    SurveyComponent,
    WaitlistComponent,
    CommunityDetailComponent,
    FrontComponent,
    StartNowComponent,
    AccountInfoComponent,
    ThankComponent,
    ForgotPassComponent,
    RegisterComponent,
    AdminstrationLoginComponent,
    SuperAdminComponent,
    AustraliaFlowComponent,
    BookRateComponent,
    ConsultantLoginComponent,
    ConsultantRegisterComponent,
    CrmRegisterComponent,
    CrmLoginComponent,
    EnquiryComponent,
    SpjainHomeComponent,
    // component create for meta tags uploading from the project
    SeoLoginComponent,
    ChartComponent,
    StudentcomHomeComponent,
    SophiyaHomeComponent,
    AtmcHomeComponent,
    ZengageHomeComponent, 
    CanomHomeComponent,
    ZengagehomeComponent,
    GradRightComponent,
    YouroshcHomeComponent,
    PaymentSuccessComponent,
    PaymentTrueComponent,
    PaymentFalseComponent,
    UcbHomeComponent,
    CranfieldHomeComponent 
    // component end for meta tags uploading from the project
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule ,
    HttpClientModule,
    SharedModuleModule,
    CKEditorModule,
    SlickCarouselModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule, 
    MatButtonModule,
    MatNativeDateModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    SlickCarouselModule,
    MatDialogModule,
    MatSelectModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar:true,
      }),
  ],
  providers: [ CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    ////console.log("main");
  }
 }
