import { Component, HostListener, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  headerFooter: boolean=false;
  headermain: boolean =true;
  public getScreenWidth: any;
  public getScreenHeight: any;

  constructor(public router: Router, private renderer: Renderer2){}
  title = '';
  onActivate(event) {
    window.scroll(0,0);
 }
 ngOnInit() {
  this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // this.headerFooter = (event.url !== '/login')
        var head = window.location.pathname.split('/');
        if(head['1'] === "maindash" || head['1']==="organisations"){
         // //console.log(window.location.pathname.split('/'));
         this.headermain = false;
        }
        // this.headermain = (event.url !== '/maindash/profile');
        // this.headermain = (event.url !== '/maindash/info');
        // this.comingFooter = (event.url !== '/coming-soon')
      }
 
    });
    this.getScreenWidth = window.innerWidth;
      this.getScreenHeight = window.innerHeight;
     if(this.getScreenWidth == 1920)
     {
      if(this.getScreenHeight < 600)
      {
        this.renderer.setStyle(document.body, 'zoom', '67%');
      }
      else if(this.getScreenHeight < 690)
      {
        this.renderer.setStyle(document.body, 'zoom', '80%');
      }
      else if(this.getScreenHeight >= 690)
      {
        this.renderer.setStyle(document.body, 'zoom', '100%');
      }
      else
      {
        this.renderer.setStyle(document.body, 'zoom', '100%');
      }
      
     }
     else if (this.getScreenWidth > 1366 && this.getScreenWidth < 1600)
     {
      this.renderer.setStyle(document.body, 'zoom', '100%');
     }
    
}

}
