import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import * as $ from 'jquery';
import { FxOfferService } from 'src/app/fx-offer-student/fx-offer.service';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
  allBlogs: any = [];
  otpStatus :boolean= false;
  showCommu:boolean=true;
  CommuDataRow: any;
  contactForm: FormGroup;
  showleftbutton: boolean;
  isSpinner: boolean;
  dataId: any;
  verifyToken: any;
  emailVerification: any;
  mobileverification: any;
  otpForm: FormGroup;
  formsubmission: boolean = false;
  Inputmode: number=1;
  InputAmount:number=100;
  Outputmode: number=1;
  OutputAmount:number;
  exchange:boolean=true;
  currencysymbol:string='attach_money';
  adminToken: any;
  Rates: any=[];
  finalCharges: any={};
  leftAmount: any=1;
  rightAmount: any;
  calcu_Amount: number=1;
  InputCurrency: string = "USD";
  selectedRate:any=''
  Token:any=''
  exchangeForm: FormGroup;
  newRate: any=[];
  selectCountry: boolean=false;
  InputCurrencyName: string='US Dollar';
  couponApply:boolean=false;
  couponVal: any;
  fxRateReturn: any;
  gstAmountt: number=0;
  gstAmount: number=0;
  valueSupply:number=0;

  nostroAmount: number=0;
  baseAmount: any;
  bankpfee: any;
  perAmount: number;
  userToken: string;
  Isloggin: boolean;
  inputAmountt: string;
  constructor(public fxoffer:FxOfferService,public router:Router,public mainservice:MainService,public _dash:DashboardService,private formBuilder : FormBuilder,private elementRef: ElementRef,private notificationService:NotifyService) { 
    this.contactForm = this.formBuilder.group({
      name: new FormControl('' , Validators.required),
      email: new FormControl('' ,  [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),Validators.maxLength(10),Validators.minLength(10)]),
      country: new FormControl('', Validators.required)
    });
    this.otpForm = this.formBuilder.group({
      otp: new FormControl('' , Validators.required)
    });
  }
  get gf(): any {
    return this.otpForm['controls'];
  }
  get sf(): any {
    return this.contactForm['controls'];
  }
  submit(contactForm){
    var submitdata = {
      'name' : contactForm.name,
      'email': contactForm.email,
      'mobile' : contactForm.phone,
      'country': contactForm.country,
      'contact_type' : 3,
    }
   // //console.log(submitdata);
    this.mainservice.EnquiryForms(submitdata).subscribe((res:Response)=>{
        if(res['success']){
          let data ={ "loginId": contactForm.email}
          this.otpStatus = true;
          this.dataId =res['data'];
          this.mobileverification = contactForm.phone;
          this.isSpinner =false;
          // this.notificationService.showSuccess("Contact Form Submitted Successfully ", "Success !!");
          // this.contactForm.reset();
       // alert('success');
      }else{
        this.notificationService.showWarning(res['message'], "failure !!");
      }
    });
  }
  verifyUser(otpForm){
    this.isSpinner =true;
    var data = {
      "otp":otpForm.otp,
      "id":this.dataId
    }
    this.mainservice.VerifyEnquiry(data).subscribe((res:Response)=>{
      this.isSpinner =false;
      if(res['success']){
        // this.notificationService.showSuccess(res['message'], "Success !!");
        this.formsubmission = true;
        // window.location.reload();
      }else{
        this.isSpinner =false;
        this.notificationService.showError(res['message'] , "Error !!",5000);
      }
    },
    (error) => {
      // //console.log("gvg",error.error['message']);
      this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
      this.notificationService.showError("error" , "Error !!",3000);
      // this.isSpinner =false;
     })
  }

  //----------GST Cal-----//
  
 
  @ViewChild('widgetsContent', {
    read: ElementRef,
    static: false
  }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
      this.showleftbutton = true;

  }
  ngOnInit() {
  }
  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
    if(this.widgetsContent.nativeElement.scrollLeft == 0){
      this.showleftbutton = false;
    }
  }
  CommuData(val){
    this.CommuDataRow = val;
    this.showCommu = false;
  }
  title = 'ng-carousel-demo';
  
  slides = [
    {
      img: "assets/images/partner/ICICIBank.png",
      link:"https://www.icicibank.com"
    },
    {
      img: "assets/images/partner/IDFCBank.png",
      link:"https://my.idfcfirstbank.com"
    },
    {
      img: "assets/images/partner/CWBAus.png",
      link:"https://www.commbank.com.au"
    },
    {
      img: "assets/images/partner/expatrio.png",
      link:"https://www.expatrio.com"
    },
    {
      img: "assets/images/partner/FIntiba.png",
      link:"https://www.fintiba.com"
    },
    {
      img: "assets/images/partner/Transcorp.png",
      link:"https://transcorpint.com"
    }
  ];
  slideConfig = {
                "slidesToShow": 5,
                "slidesToScroll": 1,
                autoplay: true,
                autoplaySpeed: 0,
                speed: 700,
                infinite: true,
                lazyLoad: "ondemand",
                responsive: [
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      autoplaySpeed: 2000,
                      speed: 100
                    }
                  }, 
                  {
                    breakpoint: 575,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      autoplaySpeed: 0,
                      speed: 700
                    }
                  }
                ]
              };
}
