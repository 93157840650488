import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start-now',
  templateUrl: './start-now.component.html',
  styleUrls: ['./start-now.component.css']
})
export class StartNowComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit() {
    let studentToken = sessionStorage.getItem("studentToken");
    if(studentToken){
    }else{
      this.router.navigate(['payer']);
    }
  }

}
