// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepagemodalbackground {
    background: #000000 0% 0% no-repeat padding-box;
    background-color: rgb(105 162 212 / 94%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(228 228 228 / 99%);
    z-index: 10001;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/payment-success/payment-success.component.css"],"names":[],"mappings":"AAAA;IACI,+CAA+C;IAC/C,wCAAwC;IACxC,2BAA2B;IAC3B,mCAAmC;AACvC;AACA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,wCAAwC;IACxC,cAAc;IACd,eAAe;AACnB","sourcesContent":[".homepagemodalbackground {\n    background: #000000 0% 0% no-repeat padding-box;\n    background-color: rgb(105 162 212 / 94%);\n    backdrop-filter: blur(10px);\n    -webkit-backdrop-filter: blur(10px);\n}\n.overlay {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgb(228 228 228 / 99%);\n    z-index: 10001;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
