import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null!;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null! : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirm_password: string = control.get('confirm_password').value; // get password from our confirm_password form control
    if (password && confirm_password && (password === confirm_password)) {
      // if they don't match, set an error in our confirm_password form control
      // control.get('confirm_password').clearValidators();
      // control.get('confirm_password').updateValueAndValidity();
      control.get('confirm_password').setErrors(null);
    }
    // compare is the password math
    if (password !== confirm_password) {
      // if they don't match, set an error in our confirm_password form control
      control.get('confirm_password').setErrors({ NoPassswordMatch: true });
    }
  }

}
