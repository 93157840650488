import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {
  id: any;
  blogDetail: any =[];

  constructor( private route: ActivatedRoute,public router: Router , public mainservice:MainService, private Notification:NotifyService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.mainservice.blogDetail(this.id).subscribe((blog:any)=>{
        this.blogDetail =blog['blogdetail'][0];
          this.Notification.setMetaData(this.blogDetail.meta_title,this.blogDetail.meta_desc,this.blogDetail.meta_keyword,
        this.blogDetail.banner,'https://reeudo.com/blogs/'+this.blogDetail.seo_url);
    });
    });  
  }

}
