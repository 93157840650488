import { Component, OnInit } from '@angular/core';
import { FxOfferService } from '../fx-offer-student/fx-offer.service';
import { FormBuilder,FormControl,FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, interval } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-payment-true',
  templateUrl: './payment-true.component.html',
  styleUrls: ['./payment-true.component.css']
})
export class PaymentTrueComponent implements OnInit {
  txnId: string;
  studentdata: any;
  adminToken: string;
  summaryinfo: any;
  isSpinner = false;
  redirectToHome: boolean = false;
  countdown: number =10; 
  univCode: string;
  private destroy$: Subject<void> = new Subject<void>();
  componentActive: boolean=true;
  displayPopUp: string;
  finalStatus: string;
  virtualData: any;
  linkId: string;
  constructor(public router:Router, public mainservice:FxOfferService,public formBuilder:FormBuilder,) { 

  }

  ngOnInit() {
    this.univCode=sessionStorage.getItem('univCode')
    
    this.startCountdown();
  
    this.txnId = sessionStorage.getItem('txnId');
    this.linkId = sessionStorage.getItem('linkId');
    this.adminToken = sessionStorage.getItem('adminToken');
    this.studentdata = JSON.parse(sessionStorage.getItem('studentDataa'));
    this.fetchPaymentDetail();
  }
  ngOnDestroy() {
    // Unsubscribe from the timer when the component is destroyed
    this.componentActive = false;
    this.destroy$.next();
    this.destroy$.complete();
  }
  fetchPaymentDetail(){
    this.isSpinner = true;
    this.mainservice.fetchpaymentDetail(this.adminToken,this.txnId,this.linkId).subscribe((res:Response)=>{
      if(res['success']){
          this.summaryinfo = res['data'].order;
          this.summaryinfo.fromCurrencyAmount = res['data'].fromCurrencyAmt;
          this.summaryinfo.fromCurrency = res['data'].fromCurrencyName;
      }
    })
  }
  goToHomePage() {
    // Unsubscribe from the timer before navigating to the home page
    this.destroy$.next();
    this.destroy$.complete();

    // Use the Router to navigate to the home page
    this.router.navigate(['/'], { replaceUrl: true });
    window.location.href='/'; 
  }

  startCountdown() {
    const timer$ = interval(1000); // Update every second

    timer$
      .pipe(
        takeWhile(() => this.countdown > 0), // Continue until countdown reaches 0
        takeUntil(this.destroy$) // Unsubscribe when the destroy$ subject emits
      )
      .subscribe(
        () => {
          this.countdown--; // Decrement the countdown value
        },
        () => {},
        () => {
          // Redirect to home component when countdown reaches 0
          if (this.componentActive) {
            this.router.navigate(['/offer/payment-list'], { replaceUrl: true });
            window.location.href='/offer/payment-list'; 
          }
        }
      );
  }

}
