import { Component, OnInit } from '@angular/core';
import { NotifyService } from '../notify.service';
import { MainService } from '../main.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  metaAbout: any;
  pageAbout: any;

  constructor(private notificationService: NotifyService,private mainservice:MainService) {
    this.mainservice.fetchPageData('about').subscribe((pageData:any)=>{
      this.pageAbout =pageData['seoDetail'][0][0];
     
  });
   }

  ngOnInit() {
    // start the code for seo Tag 
    this.mainservice.fetchPage('about').subscribe((about:any)=>{
      this.metaAbout =about['seoDetail'][0];
      this.notificationService.setMetaData(this.metaAbout.metaTitle,this.metaAbout.metaDescription,this.metaAbout.metaKeywords,
        '',this.metaAbout.seoUrl);
  });
  // end the code for seo Tag 
  }

}
