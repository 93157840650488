import { Component, OnInit } from '@angular/core';
import { FxOfferService } from '../fx-offer-student/fx-offer.service';
import { FormBuilder,FormControl,FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, interval } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  txnId: string;
  studentdata: any;
  adminToken: string;
  summaryinfo: any;
  isSpinner = false;
  redirectToHome: boolean = false;
  countdown: number =20; 
  univCode: string;
  private destroy$: Subject<void> = new Subject<void>();
  componentActive: boolean=true;
  displayPopUp: string;
  finalStatus: string;
  virtualData: any = 'null';
  linkId: string;
  constructor(public router:Router, public mainservice:FxOfferService,public formBuilder:FormBuilder,private activeRoute:ActivatedRoute) { 
    this.univCode=sessionStorage.getItem('univCode')
    
  
    this.txnId = sessionStorage.getItem('txnId');
    this.adminToken = sessionStorage.getItem('adminToken');
    this.studentdata = JSON.parse(sessionStorage.getItem('studentDataa'));
    this.fetchPaymentStatus();

  }

  ngOnInit() {
    this.linkId = this.activeRoute.snapshot?.queryParamMap.get('linkRef');
    sessionStorage.setItem('linkId',this.linkId);
    if(!this.txnId)
    {
      this.router.navigate(['/offer/payment-list'], { replaceUrl: true });
      window.location.href='/'; 
    }
  }
  ngOnDestroy() {
    // Unsubscribe from the timer when the component is destroyed
    this.componentActive = false;
    this.destroy$.next();
    this.destroy$.complete();
  }
  fetchPaymentStatus(){
    this.isSpinner = true;
    this.mainservice.fetchPaymentStatus(this.adminToken,this.txnId).subscribe((res:Response)=>{
      if(res['success']){
        if(res['data'])
        {
          this.virtualData = res['data'].status;
          if(this.virtualData == 'PAID')
          {
            this.finalStatus = "Success"; 
          }
          else if(this.virtualData == 'ACTIVE')
          {
            this.finalStatus = "Failed"; 
          }
          else
          {
            this.finalStatus = "Failed"; 
          }
          this.isSpinner = false;
        }
        
      }
    })
  }
  


}
