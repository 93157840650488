// session.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private timeoutDuration: number = 24 * 60 * 60 * 1000; // 1 day in milliseconds
  private timeoutId: any;

  constructor() {}

  startSession() {
    this.resetTimeout();
  }

  clearSession() {
    // Perform actions to clear the session, such as logging out the user, removing tokens, etc.
    // Example:
    localStorage.removeItem('userToken'); // Remove authentication token from local storage
    localStorage.removeItem('user'); 
    localStorage.removeItem('yourKey');
    localStorage.removeItem('yourKeyName');
    sessionStorage.clear(); // Clear all data in session storage
    // Additional logic as needed
  }
  resetTimeout() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => this.expireSession(), this.timeoutDuration);
  }

  expireSession() {
    // Perform actions to clear the session, e.g., logout the user
    console.log('Session expired');
  }
}
