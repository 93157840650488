import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { CustomValidators } from '../CustomValidators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  RegisterForm :FormGroup;
  admin: any;
  submitdata: any;
  registerData: any;
  verifyForm: boolean=false;
  verifyToken: any;
  dataId: any;
  userToken: any;
  emailVerification: any='';
  isSpinner:boolean=false;
  remember: boolean=true;
  interval;
  timeLeft: number;
  resendDisable: boolean=false;
  fieldTextType: boolean;
  ConfirmfieldTextType:boolean;
  loginfieldTextType:boolean;
  constructor(public router:Router,private formBuilder : FormBuilder,public _dash:DashboardService,private route: ActivatedRoute,
    public mainservice:MainService, private notificationService:NotifyService,private cookieService: CookieService ) {
    this.loginForm=formBuilder.group({
      // userType:new FormControl('',Validators.required),
      email:new FormControl('', [Validators.email , Validators.required]),
      password:new FormControl('', Validators.required),
    });
   }
   get lf() { return this.loginForm['controls'] }

    loginFieldType(){
      this.loginfieldTextType = !this.loginfieldTextType;
    }
  onSubmit(loginForm){
    this.isSpinner =true;
    let submitdata={
      "email": loginForm.email,
      "secret": loginForm.password,
      "userType":"STUDENT"
  }
  ////console.log(submitdata)
  this._dash.getToken().subscribe((token:Response)=>{
    if(token['success']){
      this._dash.getLogin(submitdata,token['data'].token).subscribe((res:Response)=>{
        this.isSpinner =false;
        if(res['success']){
          this.userToken = res['data'].token;
         sessionStorage.setItem('userToken',this.userToken);
         this._dash.getUser(this.userToken).subscribe((res:Response)=>{
          if(res['success']){
            sessionStorage.setItem('user',JSON.stringify(res['data']));
            // sessionStorage.setItem('userToken',this.userToken);
            this.notificationService.showSuccess("Logged In Successfully" , "Success !!");
            if(res['data'].userType == 'STUDENT'){
              this.router.navigate(['maindash/student-onboard']);
            }else{
              this.router.navigate(['maindash/info']);
            }
            if(this.remember){
              this.cookieService.set( 'U_type', loginForm.type );
              this.cookieService.set( 'U_email', loginForm.email );
              this.cookieService.set( 'U_pass', loginForm.password);
            }
          }else{
            this.notificationService.showError(res['message'] , "Error !!",5000);
          }
         });
        }else{
          this.isSpinner =false;
          this.notificationService.showError(res['message'] , "Error !!",5000);
        }
      },
      (error) => {
        this.isSpinner =false;
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
       }
       
      ),
      (error) => {
        this.isSpinner =false;
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
       }}
  });

  }
  // onRegister(RegisterForm){
  //   this.isSpinner =true;
  //   let reg_data ={
  //     "email": RegisterForm.email,
  //     "firstName": RegisterForm.f_name,
  //     "lastName": RegisterForm.l_name,
  //     "mobile":RegisterForm.phone,
  //     "secret": RegisterForm.password,
  //     "secretType": "PASSWORD",
  //     "userType": RegisterForm.type,
  //     "confirmSecret": RegisterForm.confirm_password,
  //     "middleName": "API"
  //   }
  //   ////console.log("re",reg_data);
  //   this._dash.getToken().subscribe((token:Response)=>{
  //     if(token['success']){
  //       this._dash.getRegister(reg_data,token['data'].token).subscribe((res:Response)=>{
  //         if(res['success']){
  //           let data ={ "loginId": RegisterForm.email}
  //           // this._dash.sendVerifyLink(data,token['data'].token).subscribe((verify:Response)=>{
  //           //   if(verify['success']){
  //           //    this.notificationService.showSuccess(verify['message'], "Success !!");
  //           this.verifyForm = true;
  //           this.dataId =res['data'].dataId;
  //           this.verifyToken = res['data'].token;
  //           this.emailVerification = RegisterForm.email;
  //           this.isSpinner =false;
  //         }else{
  //           this.isSpinner =false;
  //           this.notificationService.showError(res['message'] , "Error !!",5000);
  //         }
  //       },
  //       (error) => {
  //         // //console.log("gvg",error.error['message']);
  //         this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
  //         this.notificationService.showError(error.error['message'] , "Error !!",3000);
  //         this.isSpinner =false;
  //        })
  //     }else{
  //       this.isSpinner =false;
  //       this.notificationService.showError(token['message'] , "Error !!",5000);
  //     }
  //   },
  //   (error) => {
  //     this.notificationService.showError(error.error['message'] , "Error !!",3000);
  //     this.isSpinner =false;
  //    })
  // }
  // resend(){
  //   this.isSpinner =true;
  //   this.timeLeft=30;
  //   let data ={ "loginId": this.emailVerification}
  //   this._dash.getToken().subscribe((token:Response)=>{
  //     this._dash.sendVerifyLink(data,token['data'].token).subscribe((verify:Response)=>{
  //       if(verify['success']){
  //         this.notificationService.showSuccess(verify['message'], "Success !!");
  //         this.verifyForm = true;
  //         this.dataId =verify['data'].dataId;
  //         this.verifyToken = verify['data'].token;
  //         this.interval = setInterval(() => {
  //           if(this.timeLeft > 0) {
  //             this.timeLeft--;
  //             this.resendDisable = true;
  //           } else {
  //             this.resendDisable = false;
  //           }
  //         },1000);
  //       }else{
  //         this.isSpinner =false;
  //         this.notificationService.showError(token['message'] , "Error !!",5000);
  //       }
  //       this.isSpinner =false;
  //     },
  //     (error) => {
  //       // //console.log("gvg",error.error['message']);
  //       this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
  //       this.notificationService.showError(error.error['message'] , "Error !!",3000);
  //       this.isSpinner =false;
  //      });
  //   });

  // }
  // verifyUser(otp){
  //   this.isSpinner =true;
  //   var data = {
  //     "verificationCode":otp.value,
  //     "id":this.dataId
  //   }
  //  // //console.log("ss",otp)
  //   this._dash.verification(data,this.verifyToken ).subscribe((res:Response)=>{
  //     this.isSpinner =false;
  //     if(res['success']){
  //       this.notificationService.showSuccess(res['message'], "Success !!");
  //       window.location.reload();
  //     }else{
  //       this.isSpinner =false;
  //       this.notificationService.showError(res['message'] , "Error !!",5000);
  //     }
  //   },
  //   (error) => {
  //     // //console.log("gvg",error.error['message']);
  //     this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
  //     this.notificationService.showError("error" , "Error !!",3000);
  //     // this.isSpinner =false;
  //    })
  // }
  // register(){
  //   this.mainservice.checkType = 2;
  // }
  // login(){
  //   this.mainservice.checkType = 1;
  // }
  rememberMe(val){
    this.remember = val;
  }
  ngOnInit() {
    // this.route.queryParams.subscribe(queryparams => {
    //   if(queryparams.type!=undefined && queryparams.type!=''){
    //     if(queryparams.type == 'login'){
    //       this.mainservice.checkType = 1;
    //     }else{
    //       this.mainservice.checkType = 2;
    //     }
    //   }
    // });
    this.mainservice.LoggedIn();
    if(this.cookieService.get('U_email')){
      this.loginForm.controls['email'].setValue(this.cookieService.get('U_email'));
      this.loginForm.controls['userType'].setValue(this.cookieService.get('U_type'));
      this.loginForm.controls['password'].setValue(this.cookieService.get('U_pass'));
      //this.cookieService.deleteAll();
      //this.cookieService.delete('test');
    }
    ////console.log("cookie",this.cookieService.get('Test'));
  }
}
