import { NgModule } from "@angular/core";
import { Routes, RouterModule, Route } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { AuthGuard } from "./auth.guard";
import { AdminLoginComponent } from "./admin-login/admin-login.component";
import { CustomerServiceService } from "./customer-service.service";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AboutComponent } from "./about/about.component";
import { BlogsComponent } from "./blogs/blogs.component";
import { BlogDetailComponent } from "./blog-detail/blog-detail.component";
import { ContactComponent } from "./contact/contact.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { SurveyComponent } from "./survey/survey.component";
import { WaitlistComponent } from "./waitlist/waitlist.component";
import { CommunityDetailComponent } from "./community-detail/community-detail.component";
import { FrontComponent } from "./studentDashboard/front/front.component";
import { StartNowComponent } from "./studentDashboard/start-now/start-now.component";
import { AccountInfoComponent } from "./studentDashboard/account-info/account-info.component";
import { ThankComponent } from "./studentDashboard/thank/thank.component";
import { ForgotPassComponent } from "./forgot-pass/forgot-pass.component";
import { RegisterComponent } from "./register/register.component";
import { AdminstrationLoginComponent } from "./adminstration-login/adminstration-login.component";
import { SuperAdminComponent } from "./super-admin/super-admin.component";
import { AustraliaFlowComponent } from "./australia-flow/australia-flow.component";
import { BookRateComponent } from "./book-rate/book-rate.component";
import { ConsultantLoginComponent } from "./consultant-login/consultant-login.component";
import { ConsultantRegisterComponent } from "./consultant-register/consultant-register.component";
import { CrmRegisterComponent } from "./crm-register/crm-register.component";
import { CrmLoginComponent } from "./crm-login/crm-login.component";
import { EnquiryComponent } from "./enquiry/enquiry.component";
import { SpjainHomeComponent } from "./spjain-home/spjain-home.component";
import { SeoLoginComponent } from "./seo-login/seo-login.component";
// define the component for the reset password
import { ResetpasswordComponent } from "./resetpassword/resetpassword.component";
// end the component for the reset password
import { ChartComponent } from "./chart/chart.component";
import { StudentcomHomeComponent } from "./studentcom-home/studentcom-home.component";
import { LogoResolver } from "./logoResolver";
import { SophiyaHomeComponent } from './sophiya-home/sophiya-home.component';
import { AtmcHomeComponent } from "./atmc-home/atmc-home.component";
import { ClearSessionGuard } from "./clear-session.guard";
import { CanomHomeComponent } from "./canom-home/canom-home.component";
import { ZengageHomeComponent } from "./zengage-home/zengage-home.component";
import { ZengagehomeComponent } from "./zengagehome/zengagehome.component";
import { GradRightComponent } from "./grad-right/grad-right.component";
import { YouroshcHomeComponent } from "./youroshc-home/youroshc-home.component";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";
import { UcbHomeComponent } from "./ucb-home/ucb-home.component";
import { CranfieldHomeComponent } from "./cranfield-home/cranfield-home.component";
const routes: Routes = [
  { path: "", redirectTo: "/", pathMatch: "full" as Route["pathMatch"] },
  {
    path: "",
    component: HomeComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "Home Page" },
  },
  { path: "about-us", component: AboutComponent },
  { path: "student-ambassador", component: CommunityDetailComponent },
  {
    path: "spjainpayments",
    component: SpjainHomeComponent,
    resolve: {
      data: LogoResolver,
    }
  },
    {
      path: "zengage",
      component: ZengagehomeComponent,
      resolve: {
        data: LogoResolver,
      },
    data: { title: "SpjainHome" },
  },
  {
    path: "studentcom",
    component: StudentcomHomeComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "StudcomHome" },
  },
  {
    path: "sophiya",
    component: SophiyaHomeComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "sophiya" },
  },
  {
    path: "zengage",
    component: ZengagehomeComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "sophiya" },
  },
  {
    path: "UCB",
    component: UcbHomeComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "UCB" },
  },
  {
    path: "cranfield",
    component: CranfieldHomeComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "UCB" },
  }, 
  {
    path: "atmc",
    component: AtmcHomeComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "atmc" },
  },
  {
    path: "canam",
    component: CanomHomeComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "canam" },
  },
  {
    path: "gradright",
    component: GradRightComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "gradright" },
  },
  {
    path: "youroshc",
    component: YouroshcHomeComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "youroshc" },
  },



  
  { path: "account-opening", component: AustraliaFlowComponent },
  { path: "contactUs", component: ContactComponent },
  { path: "enquiry", component: EnquiryComponent },
  { path: "blogs", component: BlogsComponent },
  { path: "blogs/:id", component: BlogDetailComponent },
  { path: "login", component: LoginComponent },
  {
    path: "consultant-login",
    component: ConsultantLoginComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "consultant-login" },
  },
  {
    path: "crm-login",
    component: CrmLoginComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "crm-login" },
  },
  {
    path: "crm-register",
    component: CrmRegisterComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "crm-register" },
  },
  {
    path: "consultant-register",
    component: ConsultantRegisterComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "consultant-register" },
  },
  {
    path: "admin",
    component: AdminstrationLoginComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "admin" },
  },
  {
    path: "super-admin",
    component: SuperAdminComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "super-admin" },
  },
  {
    path: "register",
    component: RegisterComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "register" },
  },
  {
    path: "payment-status",
    component: PaymentSuccessComponent,
    data: { title: "payment-status" },
  },
  {
    path: "forgot-password",
    component: ForgotPassComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "forget-password" },
  },
  { path: "survey", component: SurveyComponent },
  { path: "book-rate", component: BookRateComponent },
  { path: "waitlist", component: WaitlistComponent },

  {
    path: 'clear-session',component:HomeComponent,
    canActivate: [ClearSessionGuard],
  },
  {
    path: "admin/login",
    component: AdminLoginComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "admin-login" },
  },
  // url define for meta tags uploading from the project
  {
    path: "seo/login",
    component: SeoLoginComponent,
    resolve: {
      data: LogoResolver,
    },
    data: { title: "seo-login" },
  },
  // url define for meta tags uploading from the project
  { path: "payer", component: FrontComponent },
  { path: "payer/start", component: StartNowComponent },
  // define the routing of the reset password
  { path: "reset", component: ResetpasswordComponent },
  // end the code of routing defining of the reset password
  { path: "bar", component: ChartComponent },
  { path: "payer/student/account", component: AccountInfoComponent },
  { path: "payer/thank-you", component: ThankComponent },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "maindash",
    loadChildren: () =>
      import("./maindash/maindash.module").then((m) => m.MaindashModule),
  },
  {
    path: "offer",
    loadChildren: () =>
      import("./fx-offer-student/fx-offer-student.module").then(
        (m) => m.FxOfferStudentModule
      ),
  },
  { path: "pages/:id", component: PrivacyPolicyComponent },
  { path: "404", component: NotFoundComponent },
  { path: "**", redirectTo: "/", pathMatch: "full" as Route["pathMatch"] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy : PreloadAllModules
      initialNavigation: "enabledNonBlocking",
      scrollPositionRestoration: "enabled",
      preloadingStrategy: CustomerServiceService,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
