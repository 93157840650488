import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyService } from 'src/app/notify.service';

@Component({
  selector: 'app-thank',
  templateUrl: './thank.component.html',
  styleUrls: ['./thank.component.css']
})
export class ThankComponent implements OnInit {

  constructor(public notificationService:NotifyService,public router:Router) { }
  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.notificationService.showSuccess("Content Copied To clipboard", "Copied !!");
  }
  ngOnInit() {
    let studentToken = sessionStorage.getItem("studentToken");
    if(studentToken){
      this.notificationService.showSuccess("", "Thank You !!");
    }else{
      this.router.navigate(['payer']);
    }
    localStorage.removeItem('user');
    localStorage.clear();
    sessionStorage.clear();
  }
  

}
