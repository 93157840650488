import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-book-rate',
  templateUrl: './book-rate.component.html',
  styleUrls: ['./book-rate.component.css']
})
export class BookRateComponent implements OnInit {
  exchangeForm: FormGroup;
  InputAmount:number=1;
  OutputAmount:number;
  exchange:boolean=true;
  InputCurrency: String = "USD";
  selectedRate:any=''
  Token:any=''
  newRate: any=[];
  finalCharges: any={};
  selectCountry: boolean=false;
  InputCurrencyName: string='US Dollar';
  InputCurrencyRate: any;
  myRate:boolean=true;
  otp:boolean=false;
  bookForm: FormGroup;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    ////console.log(event.target)
    if(event.target.className == 'select-con'){
      //alert( "clicked in");
    }else{
      if(this.selectCountry){
        this.getCountry();
      }
    }
  }
  constructor(public router:Router,public mainservice:MainService,public _dash:DashboardService,private formBuilder : FormBuilder,private elementRef: ElementRef) { 
    this.exchangeForm = this.formBuilder.group({
      input : new FormControl(),
      output : new FormControl(),
      input_r : new FormControl(),
      output_r : new FormControl(),
    })
    this.bookForm = this.formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      name:new FormControl('', [Validators.required]),
      otp:new FormControl(''),
    })
  }

  ngOnInit() {
  }
  amount(val){
    //console.log(val)
    this.InputAmount = val;
    this.calculation();
  }
  modeInput(val, key,name){ 
    this.InputCurrencyRate  = val;
     this.InputCurrency = key;
     this.InputCurrencyName = name;
    this.calculation();
  }
  calculation(){
    this._dash.getFinalCharnges(this.Token, this.InputCurrency, this.InputAmount).subscribe((res:Response)=>{
      if(res['success']){
        let charges:any =res['data'];
        this.finalCharges = charges['finalPaymentAmounts']['TRANSCORP'];
  
        //console.log("curr ==> ",this.finalCharges)
        this.OutputAmount = this.finalCharges;
      }
    }) 
       // //console.log(Object.keys(this.Rates))
  }
  getCountry(){
    this.selectCountry = !this.selectCountry;
  }
  newRates(){
    this._dash.getRates(this.Token).subscribe((res:Response)=>{
      if(res['success']){
      //  this.newRate =res['data']['currencyCode'];
        var i=1;
        res['data']['currencyCode'].forEach(element => {
            if(element.currencyCode == 'USD'){
              this.newRate[0] =element;
            }else if(element.currencyCode=="CAD"){
              this.newRate[1] =element;
            }else if(element.currencyCode=="GBP"){
              this.newRate[2] =element;
            }else if(element.currencyCode=="AUD"){
              this.newRate[3] =element;
            }else if(element.currencyCode=="EUR"){
              this.newRate[4] =element;
            }else{
              this.newRate[4+i]=element
              i++;
            }
        });
        //console.log("rrr",this.newRate)
        // this.newRate.shift();
        this._dash.getFinalCharnges(this.Token, this.InputCurrency, this.InputAmount).subscribe((res:Response)=>{
          if(res['success']){
            let charges:any =res['data'];
            this.finalCharges = charges['finalPaymentAmounts']['TRANSCORP'];
      
            //console.log("curr ==> ",this.finalCharges)
            this.OutputAmount = this.finalCharges;
            
           // //console.log(Object.keys(this.Rates))
          }
        })
      }
    });
  }
  ngAfterViewInit(){
    this._dash.getToken().subscribe((token:Response)=>{
      this.Token = token['data'].token
      ////console.log(this.Token);
      //this.getRates();
      this.newRates();
     })
  }
  submit(form){
    this.myRate = false;
  }
  Book(form){
    this.otp = true;
  }
}
