import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class LogoResolver  {
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const path = route.routeConfig.path; // Get the path of the current route

    
    if(path === 'spjainpayments')
    {
        sessionStorage.setItem('univCode','SPJ')
    }
    else if(path === 'sophiya')
    {
        sessionStorage.setItem('univCode','SOPHIYA')
    }
    else if(path === 'atmc')
    {
        sessionStorage.setItem('univCode','ATMC')
    }
    else if(path === 'studentcom')
    {
        sessionStorage.setItem('univCode','STUDCOM')
    }
    else if(path === 'atmc')
    {
        sessionStorage.setItem('univCode','ATMC')
    }else if(path === 'canam')
    {
        sessionStorage.setItem('univCode','CANAM')
    }
    else if(path === 'zengage')
    {
        sessionStorage.setItem('univCode','ZENGAGE')
    }
    else if(path === 'gradright')
    {
        sessionStorage.setItem('univCode','GRD');
    }
    else if(path === 'zengage')
    {
        sessionStorage.setItem('univCode','ZENGAGE')
    }
    else if(path === 'youroshc')
    {
        sessionStorage.setItem('univCode','YOUROSHC')
    }
    else if(path === 'UCB')
    {
        sessionStorage.setItem('univCode','UCB')
    }
    else if(path === 'cranfield')
    {
        sessionStorage.setItem('univCode','CRANFIELD')
    }
    else if(path === 'register')
    {
      if(sessionStorage.getItem('user'))
      {
        window.location.href='/home'; 
      }
    }
    else
    {
      let path=sessionStorage.getItem('univCode')
      sessionStorage.getItem('univCode') ? sessionStorage.removeItem('univCode') :'';
    }

return of(null);
}
  
}