import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../main.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  page: any =[];
  page_id: any;

  constructor(public router:Router,private route: ActivatedRoute,public mainservice:MainService) { }

  ngOnInit() {
    this.route.params.subscribe(params => { this.page_id = params.id;
      
    this.mainservice.pageDetail(this.page_id).subscribe((res:any)=>{
      if(res['success']){
        this.page = res['pageDetail'];
        //console.log("page",this.page);
      }
    });
  })
  }

}
