import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import * as $ from 'jquery';
import { FxOfferService } from 'src/app/fx-offer-student/fx-offer.service';
import { NotifyService } from '../notify.service';
import { CustomValidators } from '../CustomValidators';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  token: string;
  resetToken: any;
  isSpinner: boolean;
  resetForm: FormGroup;
  newfieldTextType: boolean;
  ConfirmfieldTextType: boolean;

  constructor(private activeRoute:ActivatedRoute, public fxoffer: FxOfferService, public router: Router, public mainservice: MainService, public _dash: DashboardService, private formBuilder: FormBuilder, private elementRef: ElementRef, private notificationService: NotifyService) {
    this.token = this.activeRoute.snapshot?.queryParamMap.get('token');
    this.resetForm=formBuilder.group({
      // type:new FormControl('',Validators.required),
      password: ['', Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8),
        Validators.maxLength(32)
      ])],
      confirm_password: ['', Validators.required]
    }, {
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
    });
  }
  newFieldType(){
    this.newfieldTextType = !this.newfieldTextType;
  }
  confirmFieldType(){
    this.ConfirmfieldTextType = !this.ConfirmfieldTextType;
  }
  get rf() { return this.resetForm['controls'] }
  ngOnInit() {
    this.fetchResettoken();
  }
fetchResettoken()
{

  this._dash.getResettoken(this.token).subscribe((res:Response)=>{
    if(res['success']){
      this.resetToken = res['data'];
    }
  });
}


onResetPassword()
  {
    this.isSpinner = true;
    
    
    if(this.resetForm)
    {
    const information =   {
        "password":this.resetForm.controls['password'].value,
        "confirmPassword":this.resetForm.controls['confirm_password'].value,
        "verificationToken":this.resetToken
    }
      this._dash.resetPassword(this.token,information).subscribe(data => {
        if(data['success']) {
          this.isSpinner = false;
          this.notificationService.showSuccess(data['message'] , "success"); 
          setTimeout(() => {
            localStorage.removeItem('user');
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('userToken');
            localStorage.clear();
            sessionStorage.clear();
            if(data['data'] == 'CRM_CREATOR')
            {
              window.location.href='/crm-login'; 
            }
            else if(data['data'] == 'SUPER_USER')
            {
              window.location.href='/consultant-login'; 
            }
            else if(data['data'] == 'SUPER_USER')
            {
              window.location.href='/super-admin'; 
            }
            else
            {
              window.location.href='/'; 
            }
           
            
          },3000)
        }
        else
        {
          this.isSpinner = false
          this.notificationService.showError(data['message'] , "Error !!" , 3000); 
        }
      },(errors)=>{
        this.isSpinner = false;
        if(errors.error)
        {
          this.notificationService.showError(errors.error.data , "Error !!" , 3000);  
        }
        if(errors.error.errors)
        {
          this.notificationService.showError(errors.error.errors , "Error !!" , 3000);
        }
       
      });
    }
  }
}
