import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from '../main.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  AdminLogin: FormGroup;
  submitdata: any;
  message: any = '';

  constructor(public router:Router,private formBuilder : FormBuilder,public mainservice:MainService) {
    this.AdminLogin=formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      password:new FormControl('', Validators.required),
    });
  }

  OnAdmin(AdminLogin){
    this.submitdata = {
      'email' : AdminLogin.email,
      'password': AdminLogin.password,
    },
    this.mainservice.AdminLog(this.submitdata).subscribe((res:Response)=>{
      if(res['success']){
        sessionStorage.setItem('user',JSON.stringify(res['Admin']));
       // this.router.navigate(['/dashboard']);
        window.location.href="/dashboard"
      }else{
        this.message = res['message'];
      }
    });
  }
  ngOnInit() {
    if(sessionStorage.getItem('user')){
      this.router.navigate(['/dashboard']);
    }
  }

}
