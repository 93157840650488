// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section{
    margin-top: 80px;
}
.blogs{
    background: #ececec;
}
.card{
    margin: .5rem;
}
.card-title{
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-align: left;
    min-height: 100px;
    line-height: 21px;
}
.card-text{
    text-align: left;
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 18px;
}
@media screen and (max-width:600px){
    section{
        margin-top: 65px;
    }
}    `, "",{"version":3,"sources":["webpack://./src/app/blogs/blogs.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["section{\n    margin-top: 80px;\n}\n.blogs{\n    background: #ececec;\n}\n.card{\n    margin: .5rem;\n}\n.card-title{\n    text-transform: capitalize;\n    font-size: 16px;\n    font-weight: bold;\n    color: #000;\n    text-align: left;\n    min-height: 100px;\n    line-height: 21px;\n}\n.card-text{\n    text-align: left;\n    font-size: 13px;\n    margin-bottom: 20px;\n    line-height: 18px;\n}\n@media screen and (max-width:600px){\n    section{\n        margin-top: 65px;\n    }\n}    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
