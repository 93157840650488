import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-crm-login',
  templateUrl: './crm-login.component.html',
  styleUrls: ['./crm-login.component.css']
})
export class CrmLoginComponent implements OnInit {

  loginForm: FormGroup;
  admin: any;
  submitdata: any;
  registerData: any;
  verifyForm: boolean=false;
  verifyToken: any;
  dataId: any;
  userToken: any;
  emailVerification: any='';
  isSpinner:boolean=false;
  remember: boolean=true;
  interval;
  timeLeft: number;
  resendDisable: boolean=false;
  fieldTextType: boolean;
  ConfirmfieldTextType:boolean;
  loginfieldTextType:boolean;
  constructor(public router:Router,private formBuilder : FormBuilder,public _dash:DashboardService,private route: ActivatedRoute,
    public mainservice:MainService, private notificationService:NotifyService,private cookieService: CookieService,private spinner: NgxSpinnerService) {
    this.loginForm=formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      password:new FormControl('',  [Validators.required, Validators.minLength(8),Validators.maxLength(32)]),
    });
   }

    loginFieldType(){
      this.loginfieldTextType = !this.loginfieldTextType;
    }
  onSubmit(loginForm){
    this.spinner.show();
    let submitdata={
      "email": loginForm.email,
      "secret": loginForm.password,
      "userType":'CRM_CREATOR'
  }
  ////console.log(submitdata)
  this._dash.getToken().subscribe((token:Response)=>{
    if(token['success']){
      this._dash.getLogin(submitdata,token['data'].token).subscribe((res:Response)=>{
        this.spinner.hide()
        if(res['success']){
          this.userToken = res['data'].token;
         sessionStorage.setItem('userToken',this.userToken);
         this._dash.getUser(this.userToken).subscribe((res:Response)=>{
          if(res['success']){
            sessionStorage.setItem('user',JSON.stringify(res['data']));
            // alert('login')
            // sessionStorage.setItem('userToken',this.userToken);
            this.notificationService.showSuccess("Logged In Successfully" , "Success !!");
            this.router.navigate(['maindash/exchange']);
            if(this.remember){
              this.cookieService.set( 'U_type', loginForm.type );
              this.cookieService.set( 'U_email', loginForm.email );
              this.cookieService.set( 'U_pass', loginForm.password);
            }
          }else{
            this.notificationService.showError(res['message'] , "Error !!",5000);
          }
         });
        }else{
          this.spinner.hide()
          this.notificationService.showError(res['message'] , "Error !!",5000);
        }
      },
      (error) => {
        this.spinner.hide()
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
       }
       
      ),
      (error) => {
        this.spinner.hide()
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
       }}
  });

  }

  rememberMe(val){
    this.remember = val;
  }
  // start the coding for sending the email for the reset password
  emailValidation()
  {
    if(this.loginForm.controls['email'].value)
    {
      if(!this.loginForm.controls['email'].valid)
      {
        this.notificationService.showError("Enter a Valid Email" , "Error !!",3000);
      }
      else
      {
        this.spinner.show()
        let resetValue = {
          "email":this.loginForm.controls['email'].value,
          "userType":"CRM_CREATOR"

        }
        this._dash.getToken().subscribe((token:Response)=>{
          if(token['success']){
        this._dash.resetLink(resetValue,token['data'].token).subscribe((res:Response)=>{
          this.spinner.hide()
          if(res['success']){
            this.notificationService.showSuccess("Kindly reset your password using the link that has been delivered to your email, if you haven't received it, please try again." , "Success !!");
            }
           }, (error: any) => {
            this.spinner.hide()
            this.notificationService.showError(error.error.message, 'Error !!', 5000);
          });
          }
        },
        (error) => {
          this.spinner.hide()
          this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
          this.notificationService.showError(error.error['message'] , "Error !!",3000);
         }
         
        )
      }
     }
    else
    {
      this.notificationService.showError("Firstly Enter the email ID" , "Error !!",3000);
    }
  }
  // end the coding for sending the email for the reset password
  ngOnInit() {
  this.mainservice.LoggedIn();
  if (this.cookieService.get('U_email')) {
    this.loginForm.controls['email'].setValue(this.cookieService.get('U_email'));

    // Check if 'type' control exists before setting its value
    if (this.loginForm.controls['type']) {
      this.loginForm.controls['type'].setValue(this.cookieService.get('U_type'));
    }

    this.loginForm.controls['password'].setValue(this.cookieService.get('U_pass'));
  }
}
}

