import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { CustomValidators } from '../CustomValidators';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  loginForm: FormGroup;
  RegisterForm :FormGroup;
  admin: any;
  submitdata: any;
  registerData: any;
  verifyForm: boolean=false;
  verifyToken: any;
  dataId: any;
  userToken: any;
  emailVerification: any='';
  isSpinner:boolean=false;
  remember: boolean=true;
  interval;
  timeLeft: number = 120;
  resendDisable: boolean=false;
  fieldTextType: boolean;
  ConfirmfieldTextType:boolean;
  isRegisterFlag : boolean = true;
  isOtpVerifiedFlag:boolean = false;
  isUserDetail:boolean = false;
  isPassword:boolean= false;

  loginfieldTextType:boolean;
  checkUser: any;
  VerificationForm: FormGroup;
  mobileNumberVerification: any;
  seconds: number =120;
  UserDetailForm: FormGroup;
  userDetail: any;
  PasswordForm: FormGroup;
  verificationData: any;
  display: string;
  isloginVia: any;
  temToken: any;
  loginPasswordfieldTextType: boolean;
  univCode: string;
  clearInterval: any;
  universityCode: string;
  constructor(public router:Router,private formBuilder : FormBuilder,public _dash:DashboardService,private route: ActivatedRoute,
    public mainservice:MainService, private notificationService:NotifyService,private cookieService: CookieService,private spinner: NgxSpinnerService ) {
      this.universityCode = sessionStorage.getItem('univCode');
      this.univCode = sessionStorage.getItem('univCode');
    this.RegisterForm=formBuilder.group({
     
      countryCode:new FormControl('+91',Validators.required),
      phone:new FormControl('',[Validators.required,Validators.maxLength(10),Validators.minLength(10)]),
      otp:new FormControl(''),
      password:new FormControl('',Validators.maxLength(32)),
      consent:new FormControl('',Validators.required),
     
    });
    this.UserDetailForm=formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required,Validators.maxLength(50)]),
      f_name:new FormControl('',[Validators.required, Validators.maxLength(20)]),
      l_name:new FormControl('',Validators.maxLength(20)),
     
    });
    this.PasswordForm=formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8),
        Validators.maxLength(32)
      ])],
      confirm_password: ['', Validators.required]
    }, {
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
     
    });
    this.VerificationForm=formBuilder.group({
      otp:new FormControl('', [Validators.maxLength(6),Validators.minLength(6)]),
      password:new FormControl(''),
     
    });
   
   }
    get rf() { return this.PasswordForm['controls'] }
  
    FieldType() {
      this.fieldTextType = !this.fieldTextType;
    }
    confirmFieldType() {
      this.ConfirmfieldTextType = !this.ConfirmfieldTextType;
    }
    loginViaPassword() {
      this.loginPasswordfieldTextType = !this.loginPasswordfieldTextType;
    }
    backFuncationality()
    {
      this.isRegisterFlag = true;
      this.isOtpVerifiedFlag = false;
      this.RegisterForm.controls['consent'].setValue('');
      this.VerificationForm.controls['otp'].setValue('');
        clearInterval(this.clearInterval);
    }
  onRegister(RegisterForm){
    this.seconds = 120;
    this._dash.getToken().subscribe((token:Response)=>{
      if(token['success']){
        this.temToken = token['data'].token;
        this._dash.checkUser(RegisterForm.phone,token['data'].token).subscribe((res:Response)=>{
          if(res['success']){
           this.checkUser = res['data'];
           if(this.checkUser.isRegister && this.checkUser.isUserDetail && this.checkUser.isPassword)
           {
            // this.openModal();
            this.loginType('otp');
           }
           else 
           {
            const data={
              "identifier":this.checkUser.mobileNumber,
              "countryCode":RegisterForm.countryCode
            }
            this._dash.otpsendOtpToNumber(data,token['data'].token).subscribe((res:Response)=>{
              if(res['success'])
              {
                this.isRegisterFlag = false;
                this.isOtpVerifiedFlag = true;
                this.dataId =res['data'].dataId;
                this.mobileNumberVerification = this.checkUser.mobileNumber;
                this.verifyToken = res['data'].token;
                this.clearInterval =   setInterval(() => {
                  if(this.seconds > 0) {
                    this.seconds--;
                    if(this.seconds > 0){
                      this.resendDisable = true;
                    }
                    else{
                      this.resendDisable = false;
                      
                    }
                    
                  } else {
                    this.resendDisable = false;
                  }
                },1000);
              }
              else{

              }
            });
            
           }
          }else{
            this.notificationService.showError(res['message'] , "Error !!",5000);
          }
        },
        (error) => {
          this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
          this.notificationService.showError(error.error['message'] , "Error !!",3000);
         })
      }else{
        this.notificationService.showError(token['message'] , "Error !!",5000);
      }
    },
    (error) => {
      this.notificationService.showError(error.error['message'] , "Error !!",3000);
     })
  }
  
  verifyUser(VerificationForm){
    this.isSpinner =true;
    var data = {
      "verificationCode":VerificationForm.otp,
      "id":this.dataId,
      "deviceType":"WEB",
      "deviceToken":"system"
    }
    this._dash.otpVerification(data,this.verifyToken ).subscribe((res:Response)=>{
     
      if(res['success']){
        this.notificationService.showSuccess(res['message'], "Success !!");
        this.verificationData = res['data'];
        this.verifyToken = this.verificationData.token;
        this.userToken = this.verificationData.token;
        
        if(this.checkUser.isUserDetail == false)
        {
          this.isUserDetail = true;
          this.isOtpVerifiedFlag = false;
        }
        else if(this.checkUser.isPassword == false)
        {
          this.isOtpVerifiedFlag = false;
          this.getUserdetail();
          this.isPassword = true;
        }
        // window.location.reload();
        this.isSpinner =false;
      }else{
        this.isSpinner =false;
        this.notificationService.showError(res['message'] , "Error !!",5000);
      }
    },
    (error) => {
      if(error.error['message'] == 'AUTHENTICATION_EXPIRED')
      {
        this.notificationService.showError('OTP Expired! Please Resend','',300);
      }
      else
      {
        this.notificationService.showError(error.error['message'],'',300);
      }
      this.isSpinner =false;
     })
  }
  emailValidation()
  {
    
        this.isSpinner =true;
        let resetValue = {
          "mobileNumber":this.RegisterForm.controls['phone'].value,
          "userType":"STUDENT"

        }
        this._dash.resetLink(resetValue,this.temToken).subscribe((res:Response)=>{
          this.isSpinner =false;
          if(res['success']){
            this.notificationService.showSuccess("Kindly reset your password using the link that has been delivered to your email, if you haven't received it, please try again." , "");
            }
           });
          }
  getUserdetail()
  {
    this._dash.getUserDetails(this.verifyToken).subscribe((res:Response)=>{
      if(res['success'])
      {
        this.userDetail = res['data'];
        
      }
    });
  }
  delNumber()
  {
    this.isSpinner = true;
    this.RegisterForm.controls['phone'].setValue('');
    this.isSpinner = false;
  }
  userData(companyForm){
    let data=
    {
      "authProvider":"LOCAL",
    "email":companyForm.email,
    "firstName":companyForm.f_name,
    "lastName":companyForm.l_name,
    "emailVerifiedToken":"12345433"
  }
    this.isSpinner =true;
    this._dash.updateUserDetails(data,this.userToken).subscribe((res:Response)=>{
      if(res['success']){
        this.isSpinner =false;
      if(this.checkUser.isPassword == false)
      {
        this.isUserDetail = false;
        this.isPassword = true;

      }
      }
    },(error)=>{
      this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
      //  this.notificationService.showError(error.error['message'] , "Error !!",3000);
       this.isSpinner =false;
    });
  }
  resend(){
    this.isSpinner =true;
    this.timeLeft = 120;
    this.resendDisable = false;
    let data ={ "loginId": this.emailVerification}
    this._dash.getToken().subscribe((token:Response)=>{
      this._dash.sendVerifyLink(data,token['data'].token).subscribe((verify:Response)=>{
        if(verify['success']){
          this.notificationService.showSuccess(verify['message'], "Success !!");
          this.verifyForm = true;
          this.dataId =verify['data'].dataId;
          this.verifyToken = verify['data'].token;
          this.interval = setInterval(() => {
            if(this.timeLeft > 0) {
              this.timeLeft--;
              this.resendDisable = true;
            } else {
              this.resendDisable = false;
            }
          },1000);
        }else{
          this.isSpinner =false;
          this.notificationService.showError(token['message'] , "Error !!",5000);
        }
        this.isSpinner =false;
      },
      (error) => {
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
        this.isSpinner =false;
       });
    });

  }
  createpassword(passwordForm)
  {
    this.getUserdetail();
    let password_data ={
      "authType": "PASSWORD",
      "confirmPassword": passwordForm.password,
      "password": passwordForm.password
    }
    
        this._dash.createPassword(password_data,this.userToken).subscribe((res:Response)=>{
          if(res['success'])
          {
            this.dataId =res['data'].dataId;
            this.verifyToken = res['data'].token;
            sessionStorage.setItem('userToken',this.verifyToken);
            let data:any ={
              "email": this.userDetail.emailId,
              "phone": this.userDetail.mobileNo,
              'userToken':this.verifyToken,
              'userId':res['data'].userId,
              'dataId':this.dataId
            }
            this.isSpinner = true;
            sessionStorage.setItem('studentUser',JSON.stringify(data));
            
            sessionStorage.setItem('adminToken',res['data'].token);
            if(this.univCode == 'SPJ')
            {
              window.location.href='/spjainpayments';
            }
            else if(this.univCode == 'STUDCOM')
            {
              window.location.href='/studentcom';
            }
            else if(this.univCode == 'SOPHIYA')
            {
              window.location.href='/sophiya';
            }
            else if(this.univCode == 'ATMC')
            {
              window.location.href='/atmc';
            }
            else if(this.univCode == 'CANAM')
            {
              window.location.href='/canam';
            }
            else if(this.univCode == 'ZENGAGE')
            {
              window.location.href='/zengage';
            }
            else if(this.univCode == 'UCB')
            {
              window.location.href='/UCB';
            }
            else if(this.univCode == 'CRANFIELD')
            {
              window.location.href='/cranfield';
            }
            else if(this.univCode == 'GRD')
            {
              window.location.href='/gradright';
            }
            else if(this.univCode == 'YOUROSHC')
            {
              window.location.href='/youroshc';
            }
            else
            {
              window.location.href='/home';
            }
          }
        });

  }
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    // Optionally, you can display a message to inform the user about the restriction
    this.notificationService.showError('Copying and pasting is not allowed in this field.','',2000)
  }
  ngOnInit() {
    this.univCode = sessionStorage.getItem('univCode');
  }
  openModal() {

  this.display = "block";
  
}
onCloseHandled() {
  this.display = "none";
}
registerResend()
{
  this.seconds = 120;
  this.sendOtpToMobile(this.RegisterForm.controls['countryCode'].value);
  this.notificationService.showSuccess('OTP Send','');
}
loginType(type)
{
  
  this.isloginVia = type;  
  this.display = "none";
  this.RegisterForm.controls["phone"].disable();
  this.RegisterForm.controls['phone'].updateValueAndValidity();
  if(type == 'otp')
  {
    this.RegisterForm.controls["otp"].setValidators([Validators.required]);
    this.RegisterForm.controls["otp"].setValue('');
    this.RegisterForm.controls['otp'].updateValueAndValidity();
    this.RegisterForm.controls["password"].clearValidators();
    this.RegisterForm.controls['password'].updateValueAndValidity();
    this.sendOtpToMobile(this.RegisterForm.controls['countryCode'].value);
  }
  else
  {
    this.RegisterForm.controls["password"].setValidators([Validators.required]);
    this.RegisterForm.controls["password"].setValue('');
    this.RegisterForm.controls['password'].updateValueAndValidity();
    this.RegisterForm.controls["otp"].clearValidators();
    this.RegisterForm.controls['otp'].updateValueAndValidity();
  }
}
sendOtpToMobile(code)
{
  const data={
    "identifier":this.checkUser.mobileNumber,
    "countryCode":code
  }
  this._dash.otpsendOtpToNumber(data,this.temToken).subscribe((res:Response)=>{
    if(res['success'])
    {
     
      this.dataId =res['data'].dataId;
      this.verifyToken = res['data'].token;
      this.interval = setInterval(() => {
        if(this.timeLeft > 0) {
          this.timeLeft--;
          if(this.seconds > 1){
            this.resendDisable = true;
          }
          else{
            this.resendDisable = false;
          }
        } else {
          this.resendDisable = false;
        }
      },1000);
    
    }
    else{

    }
  });
}
otpSubmit(){
  if(!this.isloginVia){
   // this.isOtp = false;
  //  this.isSpinner = false;
  }else{
    this.spinner.show();
    if(this.isloginVia == 'otp')
    {
      const data = {
        "id": this.dataId,
        "verificationCode":this.RegisterForm.controls['otp'].value,
        "deviceType":"WEB",
        "deviceToken":"system"
      }
      this._dash.otpVerification(data,this.verifyToken).subscribe((res:Response)=>{
        if(res['success']){
          this.isSpinner = true;
          // this.isOtp = true;
          this.dataId =res['data'].dataId;
          this.verifyToken = res['data'].token;
          sessionStorage.setItem('userToken',this.verifyToken);
          let data:any ={
            "email": '',
            "phone": '',
            'userToken':this.verifyToken,
            'userId':res['data'].userId,
            'dataId':this.dataId
          }
          this.spinner.hide();
          sessionStorage.setItem('studentUser',JSON.stringify(data));
          
          sessionStorage.setItem('adminToken',res['data'].token);
          if(this.univCode == 'SPJ')
          {
            window.location.href='/spjainpayments';
          }
          else if(this.univCode == 'STUDCOM')
          {
            window.location.href='/studentcom';
          }
          else if(this.univCode == 'SOPHIYA')
          {
            window.location.href='/sophiya';
          }
          else if(this.univCode == 'ATMC')
          {
            window.location.href='/atmc';
          }
          else if(this.univCode == 'CANAM')
          {
            window.location.href='/canam';
          }
          else if(this.univCode == 'ZENGAGE')
            {
              window.location.href='/zengage';
            }
            else if(this.univCode == 'UCB')
            {
              window.location.href='/UCB';
            }
            else if(this.univCode == 'CRANFIELD')
            {
              window.location.href='/cranfield';
            }
            else if(this.univCode == 'GRD')
            {
              window.location.href='/gradright';
            }
            else if(this.univCode == 'YOUROSHC')
            {
              window.location.href='/youroshc';
            }
          else
          {
            window.location.href='/home';
          }
          //--------TXN LIST---------//
        
          
         
        }else{
         this.notificationService.showError(res['message'],'',300);
        }
        this.spinner.hide();
      },
      (e) => {
        if(e.error['message'] == 'AUTHENTICATION_EXPIRED')
        {
          this.notificationService.showError('OTP Expired! Please Resend','',300);
        }
        else
        {
          this.notificationService.showError(e.error['message'],'',300);
        }
        
        this.VerificationForm.reset();
        this.spinner.hide();
      })
    }
    else
    {
      const data = {
        "identifier":this.RegisterForm.controls['phone'].value,
        "secret":this.RegisterForm.controls['password'].value,
        "userType":"STUDENT",
        "deviceType":"WEB",
        "deviceToken":"system"
      }
     
      this._dash.loginByPassword(data,this.temToken).subscribe((res:Response)=>{
        if(res['success']){
          this.spinner.show();
          // this.isOtp = true;
          this.dataId =res['data'].dataId;
          this.verifyToken = res['data'].token;
          sessionStorage.setItem('userToken',this.verifyToken);
          let data:any ={
            "email": res['data'].emailId,
            "phone": res['data'].mobileNo,
            'userToken':this.verifyToken,
            'userId':res['data'].userId,
            'dataId':this.dataId
          }
          this.spinner.show();
          sessionStorage.setItem('studentUser',JSON.stringify(data));
          
          sessionStorage.setItem('adminToken',res['data'].token);
          if(this.univCode == 'SPJ')
          {
            window.location.href='/spjainpayments';
          }
          else if(this.univCode == 'STUDCOM')
          {
            window.location.href='/studentcom';
          }
          else if(this.univCode == 'SOPHIYA')
          {
            window.location.href='/sophiya';
          }
          else if(this.univCode == 'ATMC')
          {
            window.location.href='/atmc';
          }
          else if(this.univCode == 'CANAM')
          {
            window.location.href='/canam';
          }
          else if(this.univCode == 'ZENGAGE')
          {
            window.location.href='/zengage';
          }
          else if(this.univCode == 'UCB')
          {
            window.location.href='/UCB';
          }
          else if(this.univCode == 'CRANFIELD')
          {
            window.location.href='/cranfield';
          }
          else if(this.univCode == 'GRD')
          {
            window.location.href='/gradright';
          }
          else if(this.univCode == 'YOUROSHC')
          {
            window.location.href='/youroshc';
          }
          else
          {
            window.location.href='/home';
          }
          //--------TXN LIST---------//
        
          
         
        }else{
         
        }
        this.spinner.hide();
      },
      (e) => {
       this.notificationService.showError(e.error['message'],'',3000)
       this.spinner.hide();
      })
      
    }
   
    }
   
}

  timer(){
    //this.seconds = 120;
    let m  = Math.floor(this.seconds % 3600 /60);
    let s = Math.floor(this.seconds % 3600 % 60);
    let mmain = m>0 ? m + (m == 1?":":":") : "0:";
    let smain = s>0 ? s + (s==1?"":"") : "00"; 
    return mmain+smain;
   }
}
