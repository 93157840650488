import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
import $ from 'jquery';
@Component({
  selector: 'app-community-detail',
  templateUrl: './community-detail.component.html',
  styleUrls: ['./community-detail.component.css']
})
export class CommunityDetailComponent implements OnInit {
  stuForm: FormGroup;
  fileToUpload: File;
  showleftbutton:boolean = false;
  file: any;
  showModal: boolean =false;
  list_value:any =[];
  pop : boolean = false;
  display: string;
  id: any;
  term: '';
  constructor(public router:Router, public mainservice:MainService,private formBuilder : FormBuilder, private notificationService:NotifyService) { 
    this.stuForm = this.formBuilder.group({
      name: new FormControl('' , Validators.required),
      email: new FormControl('' ,  [Validators.required, Validators.email]),
      // phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      country: new FormControl('usa'),
      college: new FormControl('' , Validators.required),
      file:new FormControl(),
    });
   // //console.log("ss",this.stuForm['name']);
  }
  fileFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.file = event.target.result;
      //console.log("imageUrl",this.file);
    };
    reader.readAsDataURL(this.fileToUpload);
  }
  submit(stuForm){
    var submitdata = {
      'name' : stuForm.name,
      'email': stuForm.email,
      'country': stuForm.country,
      'college': stuForm.college,
      'file' : (this.file) ? this.file : '',
    }
    //console.log(submitdata);
    this.mainservice.stuAmbssadorForms(submitdata).subscribe((res:Response)=>{
      if(res['success']){
        this.notificationService.showSuccess(res['message'], "Success !!");
        this.stuForm.reset();
        //window.location.reload();
     // alert('success');
        $('.close').click(); 
    }else{
      this.notificationService.showWarning(res['error'], "failure !!");
      
    }
  });
  }
  
  ngOnInit() {
     this.list_value= [
    {
       'name': 'Sheena Vig',
       'p_id': '1',
       'status': '1',
       'country':'UK',
       'university':'ExeterUniversity',
       'img'  : 'assets/images/community/PIC.png',
       'detail' : 'Hi, I am Sheena, a law graduate from The University of Exeter, Reeudo is a great service for students transferring money from India'

    },
    {
      'name': 'Akshay Gupta',
       'p_id': '2',
       'status': '1',
       'country':'UK',
       'university':'EdinburghUniversity',
       'img'  : 'assets/images/community/akshayguptareeudo.png',
       'detail' : 'Hi, I am Akshay and I have recently obtained an engineering degree from the University of Edinburgh. Reeudo is an extremely fast way to receive University tuition from India.'

   },
   {
    'name': 'Rhea Mirani',
     'p_id': '3',
     'status': '1',
     'country':'UK',
     'university':'McMasterUniversity',
     'img'  : 'assets/images/community/rheamiranireeudo.png',
     'detail' : 'Hi, I am Rhea, I study combined majors in health and society and political science at   University. My family uses Reeudo to send me money from India as it is fast and efficient.'

 },
 {
  'name': 'Rheiya Vig',
   'p_id': '4',
   'status': '1',
   'country':'UK',
   'university':'EdinburghUniversity',
   'img'  : 'assets/images/community/rheiyavigreeudo.png',
   'detail' : 'Hi, I am Rheiya, I studied Law at The University of Edinburgh. Reeudo is revolutionizing the speed, ease, cost efficiency, and convenice for Indian students abroad.'

},
{
  'name': 'Mohammed Salman',
   'p_id': '5',
   'status': '1',
   'country':'UK',
   'university':'SouthamptonUniversity',
   'img'  : 'assets/images/community/mohamadsalmanreeudo.png',
   'detail' : 'Hi, I am Mo, I studied Law at The University of Southampton. Reeudo offers a variety of discount codes that allow my family to save money when transferring it abroad.'

},
{
  'name': 'Keerthana Nair',
   'p_id': '6',
   'status': '1',
   'country':'UK',
   'university':'CardiffUniversity',
   'img'  : 'assets/images/community/keerthananairreeudo.png',
   'detail' : 'Hi, I am Keerthana, I study architecture at The University of Cardiff and I am a big foodie! Reeudo is a great way for students to receive money from India as it helps you save on the transfer commission that banks charge you.'

},
{
  'name': 'Sanjana Nair',
   'p_id': '7',
   'status': '1',
   'country':'UK',
   'university':'NewcastleUniversity',
   'img'  : 'assets/images/community/sanjananairreeudo.png',
   'detail' : 'Hi, I am Sanjana I study medicine at Newcastle University. I like Reeudo because it works out economically to transfer money across borders for students.'

},
{
  'name': 'Lionel Fernandes',
   'p_id': '8',
   'status': '1',
   'country':'UK',
   'university':'EdinburghUniversity',
   'img'  : 'assets/images/community/lionelfernandesreeudo.png',
   'detail' : ' Hi I am Lionel,  I am doing my masters in finance at The University of Edinburgh. I like Reeudo because its a quick way to receive money from India.'

},
{
  'name': 'Damini Agarwal',
   'p_id': '9',
   'status': '1',
   'country':'UK',
   'university':'EdinburghUniversity',
   'img'  : 'assets/images/community/damniniagarwalreeudo.png',
   'detail' : 'Hello, my name is Damini! I’m a student from India studying at The University of Edinburgh. Reeudo gives my parents peace of mind as they can track transactions online.'

},
];

  }
  openModal(id) {

      this.list_value
    this.display = "block";
    this.id = id;
    
  }
  onCloseHandled() {
    this.display = "none";
  }
  @ViewChild('widgetsContent', {
    read: ElementRef,
    static: false
  }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
      this.showleftbutton = true;

  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
    if(this.widgetsContent.nativeElement.scrollLeft == 0){
      this.showleftbutton = false;
    }
  }
  

}
