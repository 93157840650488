import { Component, OnInit } from '@angular/core';
import { FormBuilder , FormControl, FormGroup , Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  metaContact: any;
  pageContact: any;

  constructor(public router:Router, public mainservice:MainService,private formBuilder : FormBuilder, private notificationService:NotifyService) { 
    this.mainservice.fetchPage('contact').subscribe((contact:any)=>{
      this.metaContact =contact['seoDetail'][0];
      this.notificationService.setMetaData(this.metaContact.metaTitle,this.metaContact.metaDescription,this.metaContact.metaKeywords,
        '',this.metaContact.seoUrl);
  });
  this.mainservice.fetchPageData('contact').subscribe((pageData:any)=>{
    this.pageContact =pageData['seoDetail'][0];
   
});
    this.contactForm = this.formBuilder.group({
      name: new FormControl('' , Validators.required),
      email: new FormControl('' ,  [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      country: new FormControl('IND'),
      message: new FormControl('' , Validators.required)
    });
    //console.log("ss",this.contactForm['name']);
  }
  submit(contactForm){
    var submitdata = {
      'name' : contactForm.name,
      'email': contactForm.email,
      'mobile' : contactForm.phone,
      'country': contactForm.country,
      'contact_type' : 1,
      'message': contactForm.message
    }
   // //console.log(submitdata);
    this.mainservice.ContactForms(submitdata).subscribe((res:Response)=>{
        if(res['success']){
          this.notificationService.showSuccess("Contact Form Submitted Successfully ", "Success !!");
          this.contactForm.reset();
       // alert('success');
      }else{
        this.notificationService.showWarning(res['message'], "failure !!");
      }
    });
  }
  ngOnInit() {
    // start the code for seo Tag 
    this.mainservice.fetchPage('contact').subscribe((contact:any)=>{
      this.metaContact =contact['seoDetail'][0];
      this.notificationService.setMetaData(this.metaContact.metaTitle,this.metaContact.metaDescription,this.metaContact.metaKeywords,
        '',this.metaContact.seoUrl);
  });
  this.mainservice.fetchPageData('contact').subscribe((pageData:any)=>{
    this.pageContact =pageData['seoDetail'][0];
   
});
  // end the code for seo Tag 
  }

}
