// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section{
    margin-top: 80px;
    margin-bottom: 80px;
}
.form-group label {
    font-size: 16px;
    font-weight: 800;
    color: #0b2238;
}
.form-control {
    background-color: #f7f8fa;
    border-color: transparent;
}
.form-control:focus {
    background-color: #ffffff;
    box-shadow: none;
}
.form-group input, .form-group select  {
    height: 55px;
    padding-left: 1.25rem;
    color: #0b2238 !important;
    font-size: 16px;
    border-radius: 8px;
    font-weight: 500;
    border-color: #215d88b0;
    transition: 0.3s !important;
}
.form-group textarea{
    padding-left: 1.25rem;
    color: #0b2238 !important;
    font-size: 16px;
    border-radius: 8px;
    border-color: #215d88b0;
}
.footer-social-icon img{
    width: 9%;
    padding: 6px;
}
input.ng-invalid.ng-touched{
    border-color: #e83d3a !important;
}
button{
    border-radius: 10px;
    border: 1px solid #0ec6fb;
    color: #fff;
    font-size: 18px;
    font-weight: bold;

    padding: 10px 20px;
    background: #0ec6fb 0% 0% no-repeat padding-box;
}
.fa{
    color: #215D88;
    font-size: 18px;
}
@media screen and (max-width:600px){
    section{
        margin-top: 65px;
    }
}    `, "",{"version":3,"sources":["webpack://./src/app/contact/contact.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,qBAAqB;IACrB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;IACvB,2BAA2B;AAC/B;AACA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,uBAAuB;AAC3B;AACA;IACI,SAAS;IACT,YAAY;AAChB;AACA;IACI,gCAAgC;AACpC;AACA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,iBAAiB;;IAEjB,kBAAkB;IAClB,+CAA+C;AACnD;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["section{\n    margin-top: 80px;\n    margin-bottom: 80px;\n}\n.form-group label {\n    font-size: 16px;\n    font-weight: 800;\n    color: #0b2238;\n}\n.form-control {\n    background-color: #f7f8fa;\n    border-color: transparent;\n}\n.form-control:focus {\n    background-color: #ffffff;\n    box-shadow: none;\n}\n.form-group input, .form-group select  {\n    height: 55px;\n    padding-left: 1.25rem;\n    color: #0b2238 !important;\n    font-size: 16px;\n    border-radius: 8px;\n    font-weight: 500;\n    border-color: #215d88b0;\n    transition: 0.3s !important;\n}\n.form-group textarea{\n    padding-left: 1.25rem;\n    color: #0b2238 !important;\n    font-size: 16px;\n    border-radius: 8px;\n    border-color: #215d88b0;\n}\n.footer-social-icon img{\n    width: 9%;\n    padding: 6px;\n}\ninput.ng-invalid.ng-touched{\n    border-color: #e83d3a !important;\n}\nbutton{\n    border-radius: 10px;\n    border: 1px solid #0ec6fb;\n    color: #fff;\n    font-size: 18px;\n    font-weight: bold;\n\n    padding: 10px 20px;\n    background: #0ec6fb 0% 0% no-repeat padding-box;\n}\n.fa{\n    color: #215D88;\n    font-size: 18px;\n}\n@media screen and (max-width:600px){\n    section{\n        margin-top: 65px;\n    }\n}    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
