import { HttpClient, HttpHeaders } from '@angular/common/http';
import { identifierName } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FxOfferService {
  

  apiUrl = environment.apiSpringUrl;

   constructor(public http: HttpClient,public router:Router) { }
 
  getToken():Observable<any>{
    return this.http.get(`${this.apiUrl}/token`);
  }
  Login(data,token):Observable<any>{
     const httpOptions = {
       headers: new HttpHeaders({ 
         'Content-Type': 'application/json',
         'Authorization' : 'Auth ' + token
       })
      };
     return this.http.post<any>(`${this.apiUrl}/auth/loginViaOtp`,data,httpOptions)
  }
  LoginOtp(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/loginViaOtp/verify`,data,httpOptions)
  }
  sendVerifyLink(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/sendVerificationLink`,data,httpOptions)
  }
  getUser(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get(`${this.apiUrl}/user`,httpOptions);
  }
  //---IFSC Fetch---------//
  getIfsc(ifsc):Observable<any>{
    return this.http.get<any>(`https://ifsc.razorpay.com/${ifsc}`)
  }
  //--------Stage List------//
 stageList(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/txn`)
  }
  stageDetails(token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/txn/`+id+'/status')
  }
  //------student details ------//
  studentInfo(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/${id}/update/UNIVERSITY`,data,httpOptions)
  }
  consentInfo(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/${id}/update/CONSENT`,data,httpOptions)
  }
  imageInfo(token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/kyc/user/status`,httpOptions)
  }
  confirmOrder(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/${id}/update/CONFIRMED`,data,httpOptions)
  }
  fetchStudent(token, id) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/txn/`+id, httpOptions);
  }
  fetchpaymentDetail(token, id, linkId) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/txn/payment/order/status?paymentRef=${linkId}&txnRef=${id}`, httpOptions);
  }
  fetchPaymentStatus(token, id) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/txn/payment/status?txnId=`+id, httpOptions);
  }
  // API define for the course fetch of particualar university
  fetchCoursebyUniv(token, univName, countryName) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/global/getcourses/${univName}/${countryName}`, httpOptions);
  }
  // API end for the course fetch of particualar university
  studentKycUpload(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      }),
     };
    return this.http.post<any>(`${this.apiUrl}/kyc/user/document/upload/student`,data,httpOptions)
  }
  // BENE Details ----------//
  beneficiaryInfo(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/user/account/api/v1/user/account/beneficiary`,data,httpOptions)
  }
  //TRANSACTIONS API's
  txnList(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     return this.http.get<any>(`${this.apiUrl}/txn/list?pageIndex=0&pageSize=3`,httpOptions)
  }
  newtransaction(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/new`,data,httpOptions)
  }
  txnSummary(token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/txn/${id}/summary`,httpOptions)
  }
  //------FILE UPLOAD------//
  fileUpload(token, data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        //'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/kyc/txn/document/upload`,data,httpOptions)
  }
  // image data

  imageFetch(token,docuemntName,applicationid):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      }),
      responseType: "blob" as "json",
      observe: 'response' as "body"
     };
    return this.http.get<any>(`${this.apiUrl}/kyc/txn/document/download?documentName=${docuemntName}&applicationId=${applicationid}`,httpOptions)
  }

  //----------sender details
  senderAdd(data,token,type='PAYER'):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     return this.http.post<any>(`${this.apiUrl}/user/account`,data,httpOptions)
  }
  senderDelete(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     return this.http.post<any>(`${this.apiUrl}/user/account/delete`,data,httpOptions)
  }
  senderEditDoc(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     return this.http.get<any>(`${this.apiUrl}/kyc/user/accounts/document/status`,httpOptions)
  }
  fileUploadForSender(token, data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        //'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/kyc/user/document/upload`,data,httpOptions)
  }
  fileUploadForSenders(token, data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        //'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/kyc/user/accounts/document/upload`,data,httpOptions)
  }

//----------Bene start
  beneficiaryAdd(data,token,type='PAYEE'):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     return this.http.post<any>(`${this.apiUrl}/user/account`,data,httpOptions)
  }
  appKycDownload(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      }),
      responseType: "blob" as "json",
      observe: 'response' as 'response'
     };
    //  let datafirst :any = { observe: 'response' };
    return this.http.get<any>(`${this.apiUrl}/kyc/user/accounts/document/download/student?accountRef=${data.accountRef}&documentType=${data.documentType}&documentSide=${data.documentSide}`,httpOptions)
  }
  senderList(token,type='PAYER'):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/user/account?accountType=${type}`,httpOptions)
  }
  FinalCharnges(token,txnId,fromCurrency:String, fromCurrencyAmount:number, code?:String,platform?:string,data?):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     let coupon  = code?`&code=${code}`:``;
     let channelcode='';
     if(platform){
      channelcode=`&platform=${platform}`;
     }
    return this.http.post<any>(`${this.apiUrl}/txn/${txnId}/finalCharges?currency=${fromCurrency}&amount=${fromCurrencyAmount}${coupon}${channelcode}`,data,httpOptions)
  }
  beneficiaryList(token,type='PAYEE'):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/user/account?accountType=${type}&pageNumber=0&pageSize=20`,httpOptions)
  }
  collegeList(token,code,name):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/?countryCode=${code}&name=${name}&pageNumber=0&pageSize=10000`,httpOptions)
  }
  universityCollegeList(token,code,name,globalcode):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/?countryCode=${code}&globalCode=${globalcode}&name=${name}&pageNumber=0&pageSize=10000`,httpOptions)
  }
  universityList(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/data/countries`,httpOptions)
  }
  // add the API configure for the specific university related country
  universityListbyUniv(token,code):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/data/countries?globalCode=${code}`,httpOptions)
  }
  // end the API configure for the specific university related country
  universityaddressList(token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/${id}`,httpOptions)
  }
  // API define for the bank detail fetch of particualar university,course and country wise
  bankDetails(token,univName, countryName, courseName):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     courseName=courseName.split(" ").join('').split('(').join("").split(')').join('');
    return this.http.get<any>(`${this.apiUrl}/university/global/getAccountDetails/${univName}/${countryName}/${courseName}`,httpOptions)
  }
   // API end for the bank detail fetch of particualar university,course and country wise
  selectSenderUpdate(data,token,id,type='PAYER'):Observable<any>{
    // alert(type);
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     return this.http.post<any>(`${this.apiUrl}/txn/${id}/update/${type}`,data,httpOptions)
  }
  selectBeneficiaryUpdate(data,token,id,type='PAYEE'):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     return this.http.post<any>(`${this.apiUrl}/txn/${id}/update/${type}`,data,httpOptions)
  }
}