import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit,OnDestroy {
  allBlogs: any =[];
  metaBlogs: any;
  pageBlog: any;


  constructor(public router:Router,public mainservice:MainService, private Notification:NotifyService) {
    this.mainservice.fetchPageData('blog').subscribe((pageData:any)=>{
      this.pageBlog =pageData['seoDetail'][0];
     
  });
   }

  ngOnInit() {
    this.mainservice.allblogs().subscribe((blog:any)=>{
      this.allBlogs =blog['blogs'];
      //console.log("blogs",this.allBlogs);
      // start the code for seo Tag 
      this.mainservice.fetchPage('blog').subscribe((blog:any)=>{
        this.metaBlogs =blog['seoDetail'][0];
        this.Notification.setMetaData(this.metaBlogs.metaTitle,this.metaBlogs.metaDescription,this.metaBlogs.metaKeywords,
          '',this.metaBlogs.seoUrl);
    });
    // end the code for seo Tag 
  });
      

  }
  ngOnDestroy() {
   
  }
}
