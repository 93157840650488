import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';

export function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.css']
})
export class ForgotPassComponent implements OnInit {
  ForgotForm: FormGroup;
  UpdateForm :FormGroup;
  admin: any;
  submitdata: any;
  registerData: any;
  verifyForm: boolean=false;
  verifyToken: any;
  dataId: any;
  userToken: any;
  emailVerification: any='';
  isSpinner:boolean=false;
  remember: boolean=true;
  interval;
  timeLeft: number;
  resendDisable: boolean=false;
  fieldTextType: boolean;
  ConfirmfieldTextType:boolean;
  loginfieldTextType:boolean;
  sendOtp:boolean=true;
  verificationToken: any;
  constructor(public router:Router,private formBuilder : FormBuilder,public _dash:DashboardService,
    public mainservice:MainService, private notificationService:NotifyService,private cookieService: CookieService ) {

    this.UpdateForm=formBuilder.group({
      //verificationToken:new FormControl('',Validators.required),
      password:new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirm_password:new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, { 
      validator: ConfirmedValidator('password', 'confirm_password')
    }
    );

    this.ForgotForm=formBuilder.group({
     // type:new FormControl('STUDENT',Validators.required),
     userType:new FormControl('',Validators.required),
      email:new FormControl('', [Validators.email , Validators.required]),

    });
   }
    get rf() { return this.UpdateForm['controls'] }
    get f() { return this.ForgotForm['controls'] }
    FieldType() {
      this.fieldTextType = !this.fieldTextType;
    }
    confirmFieldType() {
      this.ConfirmfieldTextType = !this.ConfirmfieldTextType;
    }
    loginFieldType(){
      this.loginfieldTextType = !this.loginfieldTextType;
    }
  onSubmit(ForgotForm){
    this.isSpinner =true;
    let data ={
       "loginId": ForgotForm.email,
       "userType":ForgotForm.userType
     }
    ////console.log(submitdata)
    this._dash.getToken().subscribe((token:Response)=>{
      this._dash.ResetOTP(data,token['data'].token).subscribe((verify:Response)=>{
        if(verify['success']){
          this.emailVerification = ForgotForm.email;
          this.isSpinner =false;
          this.notificationService.showSuccess(verify['message'], "Success !!");
          this.sendOtp =false;
          this.dataId =verify['data'].dataId;
          this.verifyToken = verify['data'].token;
          this.interval = setInterval(() => {
            if(this.timeLeft > 0) {
              this.timeLeft--;
              this.resendDisable = true;
            } else {
              this.resendDisable = false;
            }
          },1000);
        }else{
          this.isSpinner =false;
          this.notificationService.showError(token['message'] , "Error !!",5000);
        }
        this.isSpinner =false;
      },
      (error) => {
        // //console.log("gvg",error.error['message']);
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
        this.isSpinner =false;
       });
    });

  }
  verification(val){
    if(!val){
      this.notificationService.showError("Please enter otp" , "Error !!",3000);
      return false;
    }
    this.isSpinner =true;
    let data ={
      "verificationCode":val,
      "id":this.dataId
    }
    ////console.log("re",reg_data);
    this._dash.verification(data,this.verifyToken).subscribe((res:Response)=>{
      if(res['success']){
        this.isSpinner =false;
        this.notificationService.showSuccess("Password Updated Successfully" , "Success !!");
        this.verifyForm = true;
        this.verificationToken = res['data']
      }else{
        this.isSpinner =false;
        this.notificationService.showError(res['message'] , "Error !!",5000);
      }
    },
    (error) => {
      // //console.log("gvg",error.error['message']);
      this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
      this.notificationService.showError(error.error['message'] , "Error !!",3000);
      this.isSpinner =false;
     })
  }
  onUpdate(UpdateForm){
    this.isSpinner =true;
    let reg_data ={
      //"email": UpdateForm.email,
      //"firstName": UpdateForm.f_name,
      // "lastName": UpdateForm.l_name,
      // "mobile":UpdateForm.phone,
      "password": UpdateForm.password,
      //"secretType": "PASSWORD",
       "verificationToken": this.verificationToken,
       "confirmPassword": UpdateForm.confirm_password,
      // "middleName": "API"
      "dataId"  :this.dataId
    }
    ////console.log("re",reg_data);
    this._dash.resetPinPass(reg_data,this.verifyToken).subscribe((res:Response)=>{
      if(res['success']){
        this.isSpinner =false;
        this.notificationService.showSuccess("Password Updated Successfully" , "Success !!");
          this.router.navigate(['/login'])
      }else{
        this.isSpinner =false;
        this.notificationService.showError(res['message'] , "Error !!",5000);
      }
    },
    (error) => {
      // //console.log("gvg",error.error['message']);
      this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
      this.notificationService.showError(error.error['message'] , "Error !!",3000);
      this.isSpinner =false;
     })
  }
  resend(){
    this.isSpinner =true;
    let data ={ "loginId": this.emailVerification}
    this._dash.getToken().subscribe((token:Response)=>{
      this._dash.ResetOTP(data,token['data'].token).subscribe((verify:Response)=>{
        if(verify['success']){
          this.timeLeft=60;
          this.notificationService.showSuccess(verify['message'], "Success !!");
         // this.verifyForm = true;
          this.dataId =verify['data'].dataId;
          this.verifyToken = verify['data'].token;
          this.interval = setInterval(() => {
            if(this.timeLeft > 0) {
              this.timeLeft--;
              this.resendDisable = true;
            } else {
              this.resendDisable = false;
            }
          },1000);
        }else{
          this.isSpinner =false;
          this.notificationService.showError(token['message'] , "Error !!",5000);
        }
        this.isSpinner =false;
      },
      (error) => {
        // //console.log("gvg",error.error['message']);
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
        this.isSpinner =false;
       });
    });

  }
  ngOnInit() {
    this.mainservice.LoggedIn();
  }
}
