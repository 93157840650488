// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section{
    margin-top: 80px;
}
.slidebox{
    position: absolute;
    top: 33%;
    left: 150px;
    right: 150px;
}
.slidebox h3{
    font-size: 48px;
    color: white;
    text-align: center;
    font-weight: 800;
}
@media screen and (max-width:600px){
    section{
        margin-top: 65px;
    }
    .slidebox { 
        top: 15%;
        left: 10px;
        right: 10px;
    }
    .slidebox h3{
        font-size: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/blog-detail/blog-detail.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,YAAY;AAChB;AACA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI;QACI,gBAAgB;IACpB;IACA;QACI,QAAQ;QACR,UAAU;QACV,WAAW;IACf;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":["section{\n    margin-top: 80px;\n}\n.slidebox{\n    position: absolute;\n    top: 33%;\n    left: 150px;\n    right: 150px;\n}\n.slidebox h3{\n    font-size: 48px;\n    color: white;\n    text-align: center;\n    font-weight: 800;\n}\n@media screen and (max-width:600px){\n    section{\n        margin-top: 65px;\n    }\n    .slidebox { \n        top: 15%;\n        left: 10px;\n        right: 10px;\n    }\n    .slidebox h3{\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
