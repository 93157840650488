import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  
  constructor(private toastr: ToastrService, private meta: Meta, private title: Title) { }
  
  showSuccess(message, title){
      this.toastr.success(message, title)
  }
  setMetaData(meta_title: any, meta_description: any, meta_keyword: any, imgUrl: string , og_url :string) {
    this.title.setTitle(meta_title);
    this.meta.updateTag({ name: 'title', content: meta_title });
    this.meta.updateTag({ name: 'description', content: meta_description });
    this.meta.updateTag({ name: 'keywords', content: meta_keyword });
    this.meta.updateTag({ property: 'og:title', content: meta_title });
    this.meta.updateTag({ property: 'og:description', content: meta_description });
    this.meta.updateTag({ property: 'og:url', content: og_url });
    this.meta.updateTag({ property: 'og:image', content: imgUrl });
  }
  showError(message, title, ToastConfig){
      this.toastr.error(message, title , ToastConfig)
  }
  
  showInfo(message, title){
      this.toastr.info(message, title)
  }
  
  showWarning(message, title){
      this.toastr.warning(message, title)
  }
  
}
