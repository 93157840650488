import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-adminstration-login',
  templateUrl: './adminstration-login.component.html',
  styleUrls: ['./adminstration-login.component.css']
})
export class AdminstrationLoginComponent implements OnInit {

  loginForm: FormGroup;
  admin: any;
  submitdata: any;
  registerData: any;
  verifyForm: boolean=false;
  verifyToken: any;
  dataId: any;
  userToken: any;
  emailVerification: any='';
  isSpinner:boolean=false;
  remember: boolean=true;
  interval;
  timeLeft: number;
  resendDisable: boolean=false;
  fieldTextType: boolean;
  ConfirmfieldTextType:boolean;
  loginfieldTextType:boolean;
  constructor(public router:Router,private formBuilder : FormBuilder,public _dash:DashboardService,private route: ActivatedRoute,
    public mainservice:MainService, private notificationService:NotifyService,private cookieService: CookieService ) {
    this.loginForm=formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      password:new FormControl('', Validators.required),
    });
   }

    loginFieldType(){
      this.loginfieldTextType = !this.loginfieldTextType;
    }
  onSubmit(loginForm){
    this.isSpinner =true;
    let submitdata={
      "email": loginForm.email,
      "secret": loginForm.password,
      "userType":'TRANSACTION_VIEW'
  }
  ////console.log(submitdata)
  this._dash.getToken().subscribe((token:Response)=>{
    if(token['success']){
      this._dash.getLogin(submitdata,token['data'].token).subscribe((res:Response)=>{
        this.isSpinner =false;
        if(res['success']){
          this.userToken = res['data'].token;
         sessionStorage.setItem('userToken',this.userToken);
         this._dash.getUser(this.userToken).subscribe((res:Response)=>{
          if(res['success']){
            sessionStorage.setItem('user',JSON.stringify(res['data']));
            // sessionStorage.setItem('userToken',this.userToken);
            this.notificationService.showSuccess("Logged In Successfully" , "Success !!");
            this.router.navigate(['maindash/exchange']);
            if(this.remember){
              this.cookieService.set( 'U_type', loginForm.type );
              this.cookieService.set( 'U_email', loginForm.email );
              this.cookieService.set( 'U_pass', loginForm.password);
            }
          }else{
            this.notificationService.showError(res['message'] , "Error !!",5000);
          }
         });
        }else{
          this.isSpinner =false;
          this.notificationService.showError(res['message'] , "Error !!",5000);
        }
      },
      (error) => {
        this.isSpinner =false;
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
       }
       
      ),
      (error) => {
        this.isSpinner =false;
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
       }}
  });

  }

  rememberMe(val){
    this.remember = val;
  }
  ngOnInit() {

    this.mainservice.LoggedIn();
    if(this.cookieService.get('U_email')){
      this.loginForm.controls['email'].setValue(this.cookieService.get('U_email'));
      this.loginForm.controls['type'].setValue(this.cookieService.get('U_type'));
      this.loginForm.controls['password'].setValue(this.cookieService.get('U_pass'));
    }

  }
}

