// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoSet{
    margin-top:-2rem;
}
.text-grey
{
    color:#696969;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep .mat-form-field-flex {
    background-color: #333 0% 0% no-repeat padding-box !important;
    box-shadow: 5px 5px 20px #00000029 !important;
    border-radius: 16px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  ::ng-deep .mat-form-field-outline {
    color: rgba(0,0,0,.0) !important;
}
.custom:placeholder-shown {
    font-weight: 700;
    font-size: 20px;
  }
  .footer-section{
    background:#EAEAEA !important;
    margin-top: 35px;
    bottom:0px
}
.footer-social-icon img {
    width: 17%;
    padding: 4px;
}
  .btn-pay {
    padding: 1.25rem 2rem;
    background: #10C7FC;
    border: none;
    font-size: 24px;
    border-radius: 32px;
    font-weight: 600;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  ::ng-deep .mat-form-field-infix
  {
    border-top:none !important;
  }`, "",{"version":3,"sources":["webpack://./src/app/resetpassword/resetpassword.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;;IAEI,aAAa;AACjB;AACA,iIAAiI;AACjI;IACI,6DAA6D;IAC7D,6CAA6C;IAC7C,mBAAmB;EACrB;EACA,iIAAiI;EACjI;IACE,gCAAgC;AACpC;AACA;IACI,gBAAgB;IAChB,eAAe;EACjB;EACA;IACE,6BAA6B;IAC7B,gBAAgB;IAChB;AACJ;AACA;IACI,UAAU;IACV,YAAY;AAChB;EACE;IACE,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,gBAAgB;EAClB;EACA,iIAAiI;EACjI;;IAEE,0BAA0B;EAC5B","sourcesContent":[".logoSet{\n    margin-top:-2rem;\n}\n.text-grey\n{\n    color:#696969;\n}\n/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n::ng-deep .mat-form-field-flex {\n    background-color: #333 0% 0% no-repeat padding-box !important;\n    box-shadow: 5px 5px 20px #00000029 !important;\n    border-radius: 16px;\n  }\n  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n  ::ng-deep .mat-form-field-outline {\n    color: rgba(0,0,0,.0) !important;\n}\n.custom:placeholder-shown {\n    font-weight: 700;\n    font-size: 20px;\n  }\n  .footer-section{\n    background:#EAEAEA !important;\n    margin-top: 35px;\n    bottom:0px\n}\n.footer-social-icon img {\n    width: 17%;\n    padding: 4px;\n}\n  .btn-pay {\n    padding: 1.25rem 2rem;\n    background: #10C7FC;\n    border: none;\n    font-size: 24px;\n    border-radius: 32px;\n    font-weight: 600;\n  }\n  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n  ::ng-deep .mat-form-field-infix\n  {\n    border-top:none !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
