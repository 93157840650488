// clear-session.guard.ts

import { Injectable } from '@angular/core';
import {  ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ClearSessionGuard  {
  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // Check if the URL matches the one you want to trigger session clearing
    if (state.url === '/clear-session') {
      // Perform actions to clear the session, e.g., logout the user
      this.sessionService.clearSession();
      // Redirect to another route if needed
      this.router.navigate(['/home'], { replaceUrl: true });
      window.location.href='/home'; 
    }

    // If the URL doesn't match, allow the navigation to continue
    return true;
  }
}
