import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/dashboard.service';
import { MainService } from 'src/app/main.service';
import { NotifyService } from 'src/app/notify.service';

export function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})
export class AccountInfoComponent implements OnInit {
  academicForm: FormGroup;
  usdRate: any=0.0134;
  userId: any;
  txnId: any;
  isSpinner: boolean;
  studentToken: any;
  institute:boolean=true;
  academic:boolean=false;
  bank_info:boolean=false;
  // countryData: any=[{'countryCode':'IND','countryName':'India','flagURL':'"https://restcountries.eu/data/ind.svg"'}];
  countryData: any=[];
  universityData: any=[];
  countryForm: FormGroup;
  months: any = [
    {'name':'January', 'value':'jan'},{'name':'February', 'value':'feb'},
    {'name':'March', 'value':'mar'},{'name':'April', 'value':'apr'},
    {'name':'May', 'value':'May'},{'name':'June', 'value':'jun'},
    {'name':'July', 'value':'jul'},{'name':'August', 'value':'aug'},
    {'name':'September', 'value':'sep'},{'name':'October', 'value':'oct'},
    {'name':'November', 'value':'nov'},{'name':'December', 'value':'dec'}
  ]
  startYear: any[];
  endYear: any[];
  payerForm: FormGroup;
  payer_TXN: any;
  userEmail: any;
  userName: any;
  letter: any;
  constructor(public router:Router, public mainservice:MainService,private formBuilder : FormBuilder, private notificationService:NotifyService,public _dash:DashboardService) { 

    // this.countryForm=formBuilder.group({
    //   country : new FormControl('0',[Validators.required , Validators.min(1)]),
    //  college : new FormControl('0',[Validators.required , Validators.min(1)]),
    //  // college : new FormControl('', Validators.required)
    // });

    this.academicForm = formBuilder.group({
      country : new FormControl('0',[Validators.required , Validators.min(1)]),
      college : new FormControl('',Validators.required ),
      enroll_num: new FormControl('' ),
      course: new FormControl('', Validators.required),
      course_start_year: new FormControl('' , Validators.required),
      course_start_month: new FormControl('' , Validators.required),
      course_end_year: new FormControl('' , Validators.required),
      course_end_month: new FormControl('' , Validators.required),
    });
    this.payerForm=formBuilder.group({
      bank: new FormControl('', Validators.required),
      acc_no: new FormControl('' , Validators.required),
      confirm_acc: new FormControl('' , Validators.required),
      name: new FormControl('' , Validators.required),
    }, { 
      validator: ConfirmedValidator('acc_no', 'confirm_acc')
    });
  }
  get cf():any{
    return this.academicForm['controls'];
  }
  get pf():any{
    return this.payerForm['controls'];
  }
  newtransactionStudent(){
    let data={
      "dateTime": this._dash.date(new Date()),
      "exchangeRate": this.usdRate,
      "fromCurrency": "INR",
      "toCurrency": "USD",
    }
    this._dash.newtransaction(data,this.studentToken).subscribe((res:Response)=>{
      if(res['success']){
        this.txnId = res['data'];
      }
    },
    (error) => {
      this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
      this.notificationService.showError(error.error['message'] , "Error !!",3000);
      this.isSpinner =false;
     });
  }
  getYear(){
    const currentYear = (new Date()).getFullYear();
    const nextYear = (new Date()).getFullYear()+5;
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    this.startYear =range(currentYear, currentYear - 20, -1); 
    this.endYear =range(nextYear, currentYear - 20, -1);
  }
  InstituteDetail(){
    this.institute = false;
    this.academic = true;
    this.getYear();
  }
  countrySubmit(countryForm){

  }
  onSubmit(academicForm){
    let data={
      'id':this.txnId,
      'stage':'UNIVERSITY',
      'enrollmentNumber': academicForm.enroll_num,
      'universityId': academicForm.college,
      'country':academicForm.country,
      'courseDetails':academicForm.course,
      'name': '',
      'amount': '',
      'currency':'INR',
      'courseEndMonth': academicForm.course_end_month,
      'courseStartMonth': academicForm.course_start_month,
      'courseStartYear': academicForm.course_start_year,
      'courseEndYear': academicForm.course_end_year,
      'fileId': ''
    };
    ////console.log("infoData",data)
    this._dash.studentDetails(data,this.studentToken,this.txnId).subscribe((res:Response)=>{
      if(res['success']){
       // sessionStorage.setItem(res['data'].txnId,"txnId");
        this.isSpinner =false;
        this.bank_info = true;
        this.academic =false;
      }
    },(error)=>{
      this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
       this.notificationService.showError(error.error['message'] , "Error !!",3000);
       this.isSpinner =false;
    });
  }
  onPayerSubmit(payerForm){
    let data={
      'id':this.payer_TXN,
      'stage':'PAYER_INFO',
      'bankName': payerForm.bank,
      'paymentMethod':'',
      'payerAccountName': payerForm.name,
      'accountNumber':payerForm.acc_no,
      'confirmAccountNumber':payerForm.confirm_acc,
    };
    this._dash.studentPaymentInfo(data,this.studentToken,this.txnId).subscribe((res:Response)=>{
      if(res['success']){
        sessionStorage.setItem(res['data'].txnId,"txnId");
       this.router.navigate(['payer/thank-you']);
      }
    },(error)=>{
      this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
       this.notificationService.showError(error.error['message'] , "Error !!",3000);
       this.isSpinner =false;
    });
  }

  getCountry(){
    this._dash.getCountry(this.studentToken).subscribe((res:Response)=>{
      if(res['success']){
        // res['data'].map(country=>{
        //   this.countryData.push(country);
        // });
       this.countryData = res['data'];
        //  //console.log("country",res['data'])
      }
    })
  }
  changeCountry(s) {
    // var data={
    //   'countryCode' : s,
    //   'name':'',
    //   'pageNumber':0,
    //   'pageSize':20
    // }
    // this._dash.getUniversity(this.studentToken,data).subscribe((res:Response)=>{
    //   if(res['success']){
    //     this.universityData = res['data']
    //     //  //console.log("university",res['data'])
    //   }
    // },
    // (error) => {
    //   this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
    //    this.notificationService.showError(error.error['message'] , "Error !!",3000);
    //    this.universityData = [];
    //    this.academicForm.controls['college'].setValue[0];
    //  })
    // let change = this.country.find(res => res.name == count).state;
  }
  logOut(){
    localStorage.removeItem('user');
    localStorage.clear();
    sessionStorage.clear();
    window.location.href='/payer'; 
  }
  ngOnInit() {
    this.studentToken = sessionStorage.getItem("studentToken");
    //this.studentToken = sessionStorage.getItem("studentType");
    //console.log(this.studentToken);
    if(this.studentToken){
      this.newtransactionStudent();
      this.getCountry();
    }else{
      this.router.navigate(['payer']);
    }
    this._dash.getUser(this.studentToken).subscribe((res:Response)=>{
      if(res['success']){
        this.userEmail = res['data'].email;
        this.userName = res['data'].name;
        var matches = this.userName.match(/\b(\w)/g); 
        this.letter = matches.join('')
        ////console.log(this.letter)
      }
     });
  }

}
