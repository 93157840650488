import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { CustomValidators } from '../CustomValidators';
@Component({
  selector: 'app-consultant-register',
  templateUrl: './consultant-register.component.html',
  styleUrls: ['./consultant-register.component.css']
})
export class ConsultantRegisterComponent implements OnInit {


  loginForm: FormGroup;
  RegisterForm :FormGroup;
  admin: any;
  submitdata: any;
  registerData: any;
  verifyForm: boolean=false;
  verifyToken: any;
  dataId: any;
  userToken: any;
  emailVerification: any='';
  isSpinner:boolean=false;
  remember: boolean=true;
  interval;
  timeLeft: number;
  resendDisable: boolean=false;
  fieldTextType: boolean;
  ConfirmfieldTextType:boolean;
  loginfieldTextType:boolean;
  constructor(public router:Router,private formBuilder : FormBuilder,public _dash:DashboardService,private route: ActivatedRoute,
    public mainservice:MainService, private notificationService:NotifyService,private cookieService: CookieService ) {

    this.RegisterForm=formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      f_name:new FormControl('',Validators.required),
      l_name:new FormControl('',Validators.required),
      phone:new FormControl('',Validators.required),
      // type:new FormControl('',Validators.required),
      password: ['', Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8),
        Validators.maxLength(32)
      ])],
      confirm_password: ['', Validators.required]
    }, {
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
    });
   }
    get rf() { return this.RegisterForm['controls'] }

    FieldType() {
      this.fieldTextType = !this.fieldTextType;
    }
    confirmFieldType() {
      this.ConfirmfieldTextType = !this.ConfirmfieldTextType;
    }
  onRegister(RegisterForm){
    this.isSpinner =true;
    let reg_data ={
      "email": RegisterForm.email,
      "firstName": RegisterForm.f_name,
      "lastName": RegisterForm.l_name,
      "mobile":RegisterForm.phone,
      "secret": RegisterForm.password,
      "secretType": "PASSWORD",
      "userType": "CONSULTANT",
      "confirmSecret": RegisterForm.confirm_password,
      "middleName": "API"
    }
    ////console.log("re",reg_data);
    this._dash.getToken().subscribe((token:Response)=>{
      if(token['success']){
        this._dash.getRegister(reg_data,token['data'].token).subscribe((res:Response)=>{
          if(res['success']){
            let data ={ "loginId": RegisterForm.email}

            this.verifyForm = true;
            this.dataId =res['data'].dataId;
            this.verifyToken = res['data'].token;
            this.emailVerification = RegisterForm.email;
            this.isSpinner =false;
          }else{
            this.isSpinner =false;
            this.notificationService.showError(res['message'] , "Error !!",5000);
          }
        },
        (error) => {
          // //console.log("gvg",error.error['message']);
          this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
          this.notificationService.showError(error.error['message'] , "Error !!",3000);
          this.isSpinner =false;
         })
      }else{
        this.isSpinner =false;
        this.notificationService.showError(token['message'] , "Error !!",5000);
      }
    },
    (error) => {
      this.notificationService.showError(error.error['message'] , "Error !!",3000);
      this.isSpinner =false;
     })
  }
  resend(){
    this.isSpinner =true;
    let data ={ "loginId": this.emailVerification}
    this._dash.getToken().subscribe((token:Response)=>{
      this._dash.sendVerifyLink(data,token['data'].token).subscribe((verify:Response)=>{
        if(verify['success']){
          this.timeLeft=60;
          this.notificationService.showSuccess(verify['message'], "Success !!");
          this.verifyForm = true;
          this.dataId =verify['data'].dataId;
          this.verifyToken = verify['data'].token;
          this.interval = setInterval(() => {
            if(this.timeLeft > 0) {
              this.timeLeft--;
              this.resendDisable = true;
            } else {
              this.resendDisable = false;
            }
          },1000);
        }else{
          this.isSpinner =false;
          this.notificationService.showError(token['message'] , "Error !!",5000);
        }
        this.isSpinner =false;
      },
      (error) => {
        // //console.log("gvg",error.error['message']);
        this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
        this.notificationService.showError(error.error['message'] , "Error !!",3000);
        this.isSpinner =false;
       });
    });

  }
  verifyUser(otp){
    this.isSpinner =true;
    var data = {
      "verificationCode":otp.value,
      "id":this.dataId
    }
   // //console.log("ss",otp)
    this._dash.verification(data,this.verifyToken ).subscribe((res:Response)=>{
      this.isSpinner =false;
      if(res['success']){
        this.notificationService.showSuccess(res['message'], "Success !!");
        window.location.reload();
      }else{
        this.isSpinner =false;
        this.notificationService.showError(res['message'] , "Error !!",5000);
      }
    },
    (error) => {
      // //console.log("gvg",error.error['message']);
      this.notificationService.showError(error.error['errors'] , "Error !!" , 3000);
      this.notificationService.showError("error" , "Error !!",3000);
      // this.isSpinner =false;
     })
  }
  ngOnInit() {
  }
}
