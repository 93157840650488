// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl : 'http://127.0.0.1:8000/api/',
  apiUrl : 'https://test-dashboard.reeudo.com/api/',
  // apiUrl:'https://preprod.reeudo.com/api/',
  // apiUrl : 'http://127.0.0.1:8000/api/',
  apiSpringUrl : 'https://test-aws.reeudo.com/api/v1',
  // apiSpringUrl:'https://preprod.reeudo.com/api/v1',
  // apiSpringUrl : 'https://prod.reeudo.com/api/v1', 
  // apiSpringUrl : 'https://test-aws.reeudo.com/api/v1',
//  gatewayUrl : 'https://prod.reeudo.com/api/v1',
  // gatewayUrl:'https://preprod.reeudo.com/api/v1',
  gatewayUrl : 'https://test-aws.reeudo.com/api/v1',
  apiToken : 'uhUQGZPJhuwQ3shIVkkIkBQZ9ekgT',
  // start the coding of add the connection and read timeout of api response 
  timeout: {
    connect: 30000, // 5 seconds
    read: 30000 // 30 seconds
  },
  defaultCouponStudent: 'STUDENT35',
  // end the coding of add the connection and read timeout of api response
  // 
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
