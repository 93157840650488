import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ckeditor4-angular';
import { ReadmorePipe } from '../readmore.pipe';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CKEditorModule,
  ]
})
export class SharedModuleModule { }
