import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import * as $ from 'jquery';
import { FxOfferService } from 'src/app/fx-offer-student/fx-offer.service';
import { NotifyService } from '../notify.service';
import { DataService } from '../dataSave.service';

@Component({
  selector: 'app-zengage-home',
  templateUrl: './zengage-home.component.html',
  styleUrls: ['./zengage-home.component.css']
})
export class ZengageHomeComponent {
  allBlogs: any = [];
  AmountCheck:boolean=false;
  Inputmode: number=1;
  InputAmount:number=100;
  Outputmode: number=1;
  OutputAmount:number;
  exchange:boolean=true;
  currencysymbol:string='attach_money';
  adminToken: any;
  Rates: any=[];
  finalCharges: any={};
  leftAmount: any=1;
  rightAmount: any;
  calcu_Amount: number=1;
  InputCurrency: string = "AUD";
  selectedRate:any=''
  Token:any=''
  exchangeForm: FormGroup;
  newRate: any=[];
  selectCountry: boolean=false;
  InputCurrencyName: string='Australian Dollar';
  showCommu:boolean=true;
  CommuDataRow: any;
  couponApply:boolean=false;
  couponVal: any;
  fxRateReturn: any;
  showleftbutton: boolean;
  gstAmountt: number=0;
  gstAmount: number=0;
  valueSupply:number=0;
  nostroAmount: number=0;
  tcsCharge: number=0;
  fundSource:any=[];
  fundId:number=2;
  baseAmount: any;
  bankpfee: any;
  perAmount: number;
  userToken: string;
  Isloggin: boolean;
  inputAmountt: string;
  couponName: any;
  isLoadingResults: boolean;
  couponApplyForm: FormGroup;
  bankWaiveOff: number = 1;
  nostroWaiveOff: number = 1;
  finalRate: any;
  clearInterval: any;
  siteUrl: string;
  showFullInfo = true;
  platform:string='';
  @HostListener('document:click', ['$event'])
  clickout(event) {
    ////console.log(event.target)
    if(event.target.className == 'select-con'){
      //alert( "clicked in");
    }else{
      if(this.selectCountry){
        this.getCountry();
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.showFullInfo = window.innerWidth > 768; // Adjust the width threshold as needed
  }
  constructor(public notify:NotifyService,public fxoffer:FxOfferService,public router:Router,public mainservice:MainService,public _dash:DashboardService,private formBuilder : FormBuilder,private elementRef: ElementRef) { 
    this.getCountry();
    this.platform = sessionStorage.getItem('univCode');
    this.selectCountry=false;
    window.innerWidth > 768?this.showFullInfo=true:this.showFullInfo=false;
    this.exchangeForm = this.formBuilder.group({
      input : new FormControl(),
      output : new FormControl(),
      input_r : new FormControl(),
      output_r : new FormControl(),
      coupon: new FormControl(),
    });
    this.couponApplyForm = this.formBuilder.group({
      coupon: new FormControl('',[Validators.required]), 
    });
    this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
       this.siteUrl =  event.url.replace('/', '');
      }
    });
  }
  fetchUniversityCode()
  {
    this.isLoadingResults = true;
    if(this.siteUrl)
    {
      this._dash.getUnivCode(this.Token, this.siteUrl).subscribe((res:Response)=>{
        if(res['success']){
          let univCode = res['data'].code;
          sessionStorage.setItem('univCode',univCode);
          this.isLoadingResults = false;
        }
      },(error)=>{
        this.isLoadingResults = false;
        console.log(error)
        this.notify.showError(error.error.message , "Error !!",3000);
    });
    }
  }
  get sf(): any {
    return this.exchangeForm['controls'];
  }
  amount(val){
    val>0&&val<=50000?this.AmountCheck=false:this.AmountCheck=true
    this.InputAmount = val;
    this.couponVal = '';
    this.couponApply = false;
    this.couponApplyForm.controls['coupon'].setValue('');
    this.couponApplyForm.controls["coupon"].enable();
    this.couponName = '';
    this.couponApplyForm.controls['coupon'].updateValueAndValidity();
    sessionStorage.setItem("inputAmount",val);
    this.calculation(() => {
      if (this.InputAmount >= 100 && this.InputAmount <= 50000) {
        // this.defaultCoupnApllied();
      }
    });
  }
  modeInput(val, key,name){
    this.Inputmode = val;
    this.leftAmount = val;   
     this.InputCurrency = key; 
     this.InputCurrencyName = name; 
     this.couponVal = '';
     this.couponApply = false;
     this.couponApplyForm.controls['coupon'].setValue('');
     this.couponApplyForm.controls["coupon"].enable();
     this.couponName = '';
     this.couponApplyForm.controls['coupon'].updateValueAndValidity();
    // console.log("Input val", val);
    // console.log("Input currency", key);
    this.calculation(() => {
      if (this.InputAmount >= 100 && this.InputAmount <= 50000) {
        // this.defaultCoupnApllied();
      }
    });
   // this.getCountry();
  }
  modeOutput(val){
   this.Outputmode = Number(val);
   this.rightAmount = val;
   console.log(this.OutputAmount)
   this.calculation();
  }
    calculation(callback?: () => void){
    this.isLoadingResults = true;
    this._dash.getFinalCharnges(this.Token, this.InputCurrency, this.InputAmount,this.couponName,this.fundId,this.platform).subscribe((res:Response)=>{
      if(res['success']){
        let charges:any =res['data'];
        this.finalCharges = charges['finalPaymentAmounts']['TRANSCORP'];
        this.fxRateReturn = charges;
        sessionStorage.setItem('signUpData',JSON.stringify(this.fxRateReturn))
        if(this.InputCurrency == 'GBP'){
          this.currencysymbol = 'currency_pound';
        }else if(this.InputCurrency == 'EUR'){
          this.currencysymbol = 'euro_symbol';
        }else if(this.InputCurrency == 'CNY'){
          this.currencysymbol = 'currency_yen';
        }else if(this.InputCurrency == 'JPY'){
          this.currencysymbol = 'currency_yen';
        }
        else if(this.InputCurrency == 'CHF'){
          this.currencysymbol = 'currency_franc';
        }
        else if(this.InputCurrency == 'SEK'){
          this.currencysymbol = 'kr';
        }
        else{
          this.currencysymbol = 'attach_money';
        }        
        sessionStorage.setItem("currency_symbol_cache",this.currencysymbol);
        this.baseAmount = charges['charges']['base'];
        this.OutputAmount = charges['charges']['total'];
        this.gstAmountt = charges['charges']['gst'];
        this.nostroAmount = charges['charges']['nostro'];
        this.tcsCharge = charges['charges']['tcs'];
        this.bankpfee = charges['charges']['bank'];
        this.bankWaiveOff = charges['charges']['bankWaiveOff'];
          this.nostroWaiveOff = charges['charges']['nostroWaiveOff'];
          charges['charges']['couponRate']!==0 && charges['charges']['couponCode']!==null?this.perAmount=charges['charges']['couponRate']:this.perAmount = this.baseAmount/this.InputAmount;
        this.calculationGst(res['data'].toCurrencyAmount,res['data'].toCurrencyAmount/res['data'].fromCurrencyAmount,res['data'].fromCurrencyAmount);
        if (callback && typeof callback === 'function') {
          callback();
        }
        this.isLoadingResults = false;
      }
      else
      {
        this.isLoadingResults = false;
      }
    },(error)=>{
      this.notify.showError(error.error.message , "Error !!",3000)
      this.isLoadingResults = false;
    }) 
       // //console.log(Object.keys(this.Rates))
  }
  getCountry(){
    this.selectCountry = !this.selectCountry;
    sessionStorage.setItem("currency_cache",this.InputCurrencyName);
     sessionStorage.setItem("currency_flag_cache",this.InputCurrency);
  }
  fundSourceChange(fund: number){
    this.fundId=fund;
    this.calculation();

  }
  getRates(){
    this._dash.getRates(this.Token,this.platform).subscribe((res:Response)=>{
      if(res['success']){
        let rate =res['data']['rates'];

        this.Rates = Object.entries(rate)
        this.Rates.forEach(k => {
          if(k[0] == 'INR'){
           // this.Rates.splice(0, 1);
            this.Outputmode = k[1];
            this._dash.usdRate.next(this.Outputmode);
            let y:any = Number(1/this.rightAmount);
             this.calcu_Amount = Number(parseFloat(y).toFixed(3));
          }else if (k[0] == 'USD') {
            this.rightAmount = k[1];
            this.Inputmode = k[1];
            let y:any = Number(1/this.rightAmount);
             this.calcu_Amount = Number(parseFloat(y).toFixed(3));
          }
        });
        this.OutputAmount =  this.InputAmount * (this.Outputmode/this.Inputmode);
        this.selectedRate = this.Rates[0].value;
        console.log("curr",this.selectedRate)

        this._dash.getFinalCharnges(this.Token, this.InputCurrency, this.InputAmount, this.couponVal,this.fundId,this.platform).subscribe((res:Response)=>{
          if(res['success']){
            let charges:any =res['data'];
            this.finalCharges = charges['finalPaymentAmounts']['TRANSCORP'];
            this.fxRateReturn = charges;
      
            console.log("curr ==> ",this.finalCharges)
            this.OutputAmount = this.finalCharges;
          }
        })
      }
    })
  }
  newRates(callback?: () => void){
    this.isLoadingResults = true;
    this._dash.getRates(this.Token,this.platform).subscribe((res:Response)=>{
      if(res['success']){
      //  this.newRate =res['data']['currencyCode'];
        var i=1;
        // console.log(res);
        res['data']['currencyCode'].forEach(element => {
            if(element.currencyCode == 'USD'){
              this.newRate[0] =element;
            }else if(element.currencyCode=="CAD"){
              this.newRate[1] =element;
            }else if(element.currencyCode=="GBP"){
              this.newRate[2] =element;
            }else if(element.currencyCode=="AUD"){
              this.newRate[3] =element;
            }else if(element.currencyCode=="EUR"){
              this.newRate[4] =element;
            }else{
              this.newRate[4+i]=element
              i++;
            }
        });
        this._dash.getFinalCharnges(this.Token, this.InputCurrency, this.InputAmount, this.couponName,this.fundId,this.platform).subscribe((res:Response)=>{
          if(res['success']){
            console.log(res)
            let charges:any =res['data'];
            this.finalCharges = charges['finalPaymentAmounts']['TRANSCORP'];
            this.baseAmount = charges['charges']['base'];
            this.gstAmountt = charges['charges']['gst'];
            this.bankpfee = charges['charges']['bank'];
            this.nostroAmount = charges['charges']['nostro'];
            this.tcsCharge = charges['charges']['tcs'];
            this.fxRateReturn = charges;
            this.OutputAmount = charges['charges']['total'];
            this.couponApply?this.perAmount = charges['charges']['couponRate']:this.perAmount = this.baseAmount/this.InputAmount;
            if (callback && typeof callback === 'function') {
              callback();
            }
            this.isLoadingResults = false
          }else{
            this.isLoadingResults=false;
          }
        },(error)=>{
          this.isLoadingResults = false;
        })
      }
      else
      {
        this.isLoadingResults = false;
      }
    },(error)=>{
      this.isLoadingResults = false;
    });
  }
  fetchFundSources(){
    this._dash.getFundSource(this.Token).subscribe(
      (response) => {
        this.fundSource=response.data
      },
      (error) => {
      }
    );
  }
  defaultCoupnApllied(){
  const defaultCoupon = "Sophiya06"; // Replace with your default coupon code
  this.couponApplyForm.controls['coupon'].setValue(defaultCoupon);
  this.applyCoupon(); // Automatically apply the default coupon
  }
  ngAfterViewInit(){
    this._dash.getToken().subscribe((token:Response)=>{
      this.Token = token['data'].token
      ////console.log(this.Token);
      //this.getRates();
      this.newRates(()=>{
        if (this.InputAmount >= 100 && this.InputAmount <= 50000) {
          // this.defaultCoupnApllied();
        }
      });
      this.amount("100");
      this.fetchFundSources();
      this.intervalHit();
      this.fetchUniversityCode(); 
      // this.defaultCoupnApllied();  
     }) 
  }
  intervalHit()
  {
    this.clearInterval = setInterval(() => {
      this.newRates();
     }, 60000); 
  }
  ngOnDestroy() {
    clearInterval(this.clearInterval);
  }
  CommuData(val){
    this.CommuDataRow = val;
    this.showCommu = false;
  }
  next(){
    this.isLoadingResults = true;
    let data = {
      'currencyName':this.InputCurrency,
      'total':this.OutputAmount
    }
    
    this.fxRateReturn.coupon = this.couponVal;
    this.fxRateReturn.couponName = this.couponName;
    this.fxRateReturn.currencysymbol = this.currencysymbol;
    sessionStorage.setItem('signUpData',JSON.stringify(this.fxRateReturn))
    if(!this.Isloggin){
      window.location.href='/register'; 
      this.isLoadingResults = false;
    }else{
      this.newTxn(this.userToken);
      setTimeout(() => {
        this.isLoadingResults = false;
        this.router.navigate(["/offer/student-detail"]);
      }, 4000);
    }
    
  }
  newTxn(token){
    let data = sessionStorage.getItem('signUpData');
    let universityCode = sessionStorage.getItem('univCode');
    if(data){
     let outcome = JSON.parse(data)
     let inputAmount = sessionStorage.getItem('inputAmount');
     let couponAMount = outcome['charges']['couponCode'];
     if(couponAMount != null)
     {
       this.finalRate = outcome['charges']['couponRate'];
     }
     else
     {
       this.finalRate = 0;
     }
     if(universityCode)
     {
      var final:any ={
        "dateTime": "string",
        "exchangeRate": outcome['charges']['base']/outcome.fromCurrencyAmount,
        "discountExchangeRate":this.finalRate,
        "ibrRate":outcome['charges']['ibrRate'],
        "couponCode":outcome.couponName,
        "fromCurrency": outcome.fromCurrency,
        "toCurrency": outcome.toCurrency,
        "amount":inputAmount,
        "globalCode":universityCode,
        "channel":"ZENGAGE"
      }
     }
     else
     {
      var final:any ={
        "dateTime": "string",
        "exchangeRate": outcome['charges']['base']/outcome.fromCurrencyAmount,
        "discountExchangeRate":this.finalRate,
        "ibrRate":outcome['charges']['ibrRate'],
        "couponCode":outcome.couponName,
        "fromCurrency": outcome.fromCurrency,
        "toCurrency": outcome.toCurrency,
        "amount":inputAmount
      }
     }
      
    }else{
      var final:any={
        "dateTime": new Date(),
        "exchangeRate": 80,
        "fromCurrency": "USD",
        "toCurrency": "INR",
        "amount":this.inputAmountt
      }
    }
    this.fxoffer.newtransaction(token,final).subscribe((res:Response)=>{
      if(res['success']){
        sessionStorage.setItem('txnId',res['data']);
        this.txnSummary(res['data'],token)
       // this.txnList(token);
      }
    })
  }
  txnSummary(txnId,token){
    sessionStorage.setItem('txnId',txnId);
    this.fxoffer.txnSummary(token,txnId).subscribe((res:Response)=>{
      if(res['success']){
        sessionStorage.setItem('txnSummary',JSON.stringify(res['data']));
      }
    })
  }
  ngOnInit() {
   
    this.userToken = sessionStorage.getItem('userToken');
    // alert(this.userToken);
    if(this.userToken){
      this.Isloggin = true;
    }
    this.InputCurrencyName = sessionStorage.getItem('currency_cache');
    this.InputCurrency = sessionStorage.getItem('currency_flag_cache');
    this.currencysymbol = sessionStorage.getItem('currency_symbol_cache');
    if(!this.InputCurrencyName){
      this.InputCurrencyName='US Dollar';
    }
    if(!this.InputCurrency){
      this.InputCurrency="USD";
    }
    if(!this.currencysymbol){
      this.currencysymbol="attach_money";
    }
    this.mainservice.allblogs().subscribe((blog:any)=>{
      this.allBlogs =blog['blogs'];
     // //console.log("blogs",this.allBlogs);
  });
  // this.newRates();
  //this.amount('100');

  }
  //----------GST Cal-----//
  calculationGst(inr,rate,curr){
    inr = curr * rate;
    if(inr <= 100000){
      let supp:number = inr * 0.01;
      //console.log(supp)
      if(supp <= 250){
        this.gstAmount = 45;
         this.valueSupply = 250;
      }else{
         this.valueSupply = supp; 
        this.gstAmount = supp * 0.18;
      }
    }else if(inr > 100000 && inr <= 1000000){
      let supp:number = (inr-100000) * 0.005;
       this.valueSupply = supp + 1000; 
       this.gstAmount = this.valueSupply * 0.18;

    }else if(inr > 1000000){
      let supp:number = (inr-1000000) * 0.001;
       this.valueSupply = supp + 5500; 
       this.gstAmount = this.valueSupply * 0.18;
    }
   // this.sale = inr + this.gstAmount;
  }
  applyCoupon(){
    this.isLoadingResults = true;
    const val = this.couponApplyForm.controls['coupon'].value;
    if(val){
      this._dash.getFinalCharnges(this.Token, this.InputCurrency, this.InputAmount,val,this.fundId,this.platform).subscribe((res:Response)=>{
        if(res['success']){
          this.isLoadingResults = false;
          let charges:any =res['data'];
          this.finalCharges = charges['finalPaymentAmounts']['TRANSCORP'];
          this.fxRateReturn = charges;
          this.OutputAmount = charges['charges']['total'];
          this.calculationGst(res['data'].toCurrencyAmount,res['data'].toCurrencyAmount/res['data'].fromCurrencyAmount,res['data'].fromCurrencyAmount)
          this.couponApply = true;
          this.couponVal = charges['charges']['youSave'];
          this.baseAmount = charges['charges']['base'];
          this.gstAmountt = charges['charges']['gst'];
          this.nostroAmount = charges['charges']['nostro'];
          this.tcsCharge = charges['charges']['tcs'];
          this.bankpfee = charges['charges']['bank'];
          this.perAmount = charges['charges']['couponRate'];
          this.bankWaiveOff = charges['charges']['bankWaiveOff'];
          this.nostroWaiveOff = charges['charges']['nostroWaiveOff'];
          this.couponApplyForm.controls["coupon"].disable();
          this.couponApplyForm.controls['coupon'].updateValueAndValidity();
          this.couponName = val;
         
        }
        else
        {
          this.notify.showError('Invalid Coupon Code' , "Error !!",3000);
        }
      },(error)=>{
        // this.isSpinner =false;
        this.isLoadingResults = false;
        this.notify.showError(error.error.message , "Error !!",3000);
    }) 
    }
  }
   navigateToCompareRate(){
    window.open('https://comparerates.reeudo.com/', '_blank');
  }
  removeCoupon(){
    this.isLoadingResults = true;
    this.couponApply = false;
    this.exchangeForm.controls['coupon'].setValue('');
    this.couponApplyForm.controls['coupon'].setValue('');
    this.couponApplyForm.controls["coupon"].enable();
    this.couponApplyForm.controls['coupon'].updateValueAndValidity();
    this.couponVal = '';
    this.couponName = '';
    this.isLoadingResults = false
    this.calculation();
  }
  @ViewChild('widgetsContent', {
    read: ElementRef,
    static: false
  }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
      this.showleftbutton = true;

  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
    if(this.widgetsContent.nativeElement.scrollLeft == 0){
      this.showleftbutton = false;
    }
  }
  title = 'ng-carousel-demo';
  
  slides = [
    {
      img: "assets/images/partner/ICICIBank.png",
      link:"https://www.icicibank.com"
    },
    {
      img: "assets/images/partner/IDFCBank.png",
      link:"https://my.idfcfirstbank.com"
    },
    {
      img: "assets/images/partner/CWBAus.png",
      link:"https://www.commbank.com.au"
    },
    {
      img: "assets/images/partner/expatrio.png",
      link:"https://www.expatrio.com"
    },
    {
      img: "assets/images/partner/FIntiba.png",
      link:"https://www.fintiba.com"
    },
    {
      img: "assets/images/partner/Transcorp.png",
      link:"https://transcorpint.com"
    }
  ];
  slideConfig = {
                "slidesToShow": 5,
                "slidesToScroll": 1,
                autoplay: true,
                autoplaySpeed: 0,
                speed: 700,
                infinite: true,
                lazyLoad: "ondemand",
                responsive: [
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      autoplaySpeed: 2000,
                      speed: 100
                    }
                  }, 
                  {
                    breakpoint: 575,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      autoplaySpeed: 0,
                      speed: 700
                    }
                  }
                ]
              };

}
