import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  //apiUrl = 'https://test-aws.reeudo.com/api/v1'
 // apiUrl = 'https://test-aws.reeudo.com/api/v1';
 apiUrl = environment.apiSpringUrl;
 gatewayUrl = environment.gatewayUrl;
  token = new BehaviorSubject('');
  checkType = 1; 
  usdRate =  new BehaviorSubject<number>(0.013); 
  //s
//  httpOptions = {
//   headers: new HttpHeaders({ 
//     'Content-Type': 'application/x-www-form-urlencoded',
//     'Authorization' : 'Auth ' + this.token
//   })
//  };
  constructor(public http: HttpClient,public router:Router) { }

  getToken():Observable<any>{
    return this.http.get(`${this.apiUrl}/token`);
  }
  getLogin(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/login`,data,httpOptions)
  }
  getLoginConsultant(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/org/login`,data,httpOptions)
  }
  // start the coding for define the API of the hit email when click on forget password
  resetLink(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/app/auth/forgetPassword`,data,httpOptions)
  }
  sendCredentialToUserByEmail(body: any,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/auth/user/mail/send`,body, httpOptions)
  }
  universityUserResetLink(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/user/mail/send`,data,httpOptions)
  }
  deleteUniversityUser(token,data:string):Observable<any>{
    console.log(token)
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.delete<any>(`${this.apiUrl}/auth/user/delete?uuid=${data}`,httpOptions);
  }
  // end the coding for define the API of the hit email when click on forget password
  getUser(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get(`${this.apiUrl}/user`,httpOptions);
  }
  getUserDetails(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get(`${this.apiUrl}/user/getUser`,httpOptions);
  }
  getCurrentUniversity(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     }; 
    return this.http.get(`${this.apiUrl}/txn/university/fetch/name`,httpOptions);
  }
  updateUserDetails(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/user/updateUser`,data,httpOptions)
  }
  getRegister(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/signup`,data,httpOptions)
  }
  checkUser(mobile,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     return this.http.get<any>(`${this.apiUrl}/auth/checkUser?mobileNumber=${mobile}`,httpOptions)
  }
  otpsendOtpToNumber(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/sendOtpToNumber`,data,httpOptions)
  }
  createPassword(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/createPassword`,data,httpOptions)
  }
  resetPinPass(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/resetPinPass`,data,httpOptions)
  }
  studentRegister(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/register/student`,data,httpOptions)
  }
  ResetOTP(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/init_reset`,data,httpOptions)
  }
  sendVerifyLink(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/sendVerificationLink`,data,httpOptions)
  }
  getTransactiondata(token,duration,sDate,eDate,cName,purpose)
  {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      }),
      params:({
        startDate: sDate,
        endDate: eDate,
        purpose: purpose,
        consultantId: cName,
        duration: duration,
     }),
     };
    return this.http.get<any>(`${this.apiUrl}/report/dashboard/consultant/reportingData`,httpOptions)
  }
  getMyearningdata(token,duration,startDate,endDate,cName)
  {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      }),
      params:({
        startDate: startDate,
        endDate: endDate,
        consultantId: cName,
        duration: duration,
     }),
     };
    return this.http.get<any>(`${this.apiUrl}/report/dashboard/myearning`,httpOptions)
  }
  getEditQuotationCurrent(quotationId: number,adminToken: string): Observable<any> {
    const url = `${this.apiUrl}/txn/quotation/get/currentRates?quotationId=${quotationId}`;
    const headers = new HttpHeaders({
      'Authorization': `Auth ${adminToken}`
    });

    // Include the headers as options in the HTTP request
    const options = {
      headers: headers
    };

    return this.http.get(url, options);
  }
  getEditQuotationAmount(quotationId: number,adminToken: string): Observable<any> {
    const url = `${this.apiUrl}/txn/quotation/get/amount?quotationId=${quotationId}`;
    const headers = new HttpHeaders({
      'Authorization': `Auth ${adminToken}`
    });

    // Include the headers as options in the HTTP request
    const options = {
      headers: headers
    };

    return this.http.get(url, options);
  }
  getEditQuotationTotalCharges(adminToken: string, formValues: any,quotationId:any): Observable<any> {
    const url = `${this.apiUrl}/txn/quotation/get/totalCharges?quotationId=${quotationId}`;
    const headers = new HttpHeaders({
      'Authorization': `Auth ${adminToken}`
    });
  
    // Include the headers in the HTTP request
    const options = {
      headers: headers
    };
  
    return this.http.post(url, formValues, options);
  }
  updateQuotation(quotationId: number,adminToken: string, formValues: any): Observable<any> {
    const url = `${this.apiUrl}/txn/quotation/update/amount?quotationId=${quotationId}`;
    const headers = new HttpHeaders({
      'Authorization': `Auth ${adminToken}`
    });
  
    // Include the headers in the HTTP request
    const options = {
      headers: headers
    };
  
    return this.http.post(url, formValues, options);
  }
  updateConsultantLogo(uuid: string,adminToken: string, logo: any): Observable<any> {
    console.log(logo);
    const url = `${this.apiUrl}/kyc/s3/consultant/uploadLogo?userId=${uuid}`;
    const headers = new HttpHeaders({
      'Authorization': `Auth ${adminToken}`
    });
  
    // Include the headers in the HTTP request
    const options = {
      headers: headers
    };
  
    return this.http.post(url, logo, options);
  }
  getFundSource(adminToken: string,): Observable<any> {
    const url = `${this.apiUrl}/txn/system/source/all`;
    const headers = new HttpHeaders({
      'Authorization': `Auth ${adminToken}`
    });
    // Include the headers as options in the HTTP request
    const options = {
      headers: headers
    };

    return this.http.get(url, options);
  }
  getConsultantListing(token)
  {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/report/consultantNames`,httpOptions)
  }
  getMyearningTable(token,duration,startDate,endDate,pageNumber,pageSize,cName)
  {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      }),
      params:({
        startDate: startDate,
        endDate: endDate,
        pageNumber: pageNumber,
        pageSize: pageSize,
        consultantId: cName,
        duration: duration,
     }),
     };
    return this.http.get<any>(`${this.apiUrl}/report/dashboard/recenttxnlist`,httpOptions)
  }
  createNewUserforUniversity(token: string, email: string, name: string, uniId: string) {
    const payload = {
      email: email,
      name: name,
      uniId: uniId,
    };
  
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Auth ' + token
      }),
    };
  
    return this.http.post<any>(`${this.apiUrl}/auth/register/org`, payload, httpOptions);
  }
  downloadMyearningTable(token,duration,startDate,endDate,cName)
  {
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      }),
      responseType: "blob" as "json",
      observe: 'response' as 'response',
      params:({
        startDate: startDate,
        endDate: endDate,
        consultantId: cName,
        duration: duration,
     }),
     };
    return this.http.get<any>(`${this.apiUrl}/report/dashboard/recenttxnlist/download`,httpOptions)
  }
  getGraphdata(token,duration,cName,purpose)
  {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      }),
      params:({
       
          duration: duration,
          consultantId: cName
       }),
     };
     
    return this.http.get<any>(`${this.apiUrl}/report/dashboard/consultant/graph`,httpOptions)
  }
  verification(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/verify`,data,httpOptions)
  }
  otpVerification(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/otpVerify`,data,httpOptions)
  }

  loginByPassword(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/loginViaMobile`,data,httpOptions)
  }
  verificationStudent(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/verify/student`,data,httpOptions)
  }
  getCountry(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/data/countries`,httpOptions)
  }
  getbank(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/txn/data/banks`,httpOptions)
  }
  getUniversity(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     if(data['name']==''){
      data['name'] = "a"  
     }
    return this.http.get<any>(`${this.apiUrl}/university/?countryCode=${data.countryCode}&name=${data.name}&pageNumber=0&pageSize=20`,httpOptions)
  }
  getRates(token,channel?:string):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     let channelcode='';
     if(channel){
      channelcode=`?platform=${channel}`;
     }
     
    return this.http.get<any>(`${this.gatewayUrl}/txn/currency/exchangeRate${channelcode}`,httpOptions)
  }
  getChannelForMarkup(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/global/get/all`,httpOptions)
  }
  getAllUniversity(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/global/get/all?orgType=UNIVERSITY`,httpOptions)
  }
  getUniversityUserList(token,search,pageSize,pageIndex):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/user/org/getAll?pageNumber=${pageIndex}&pageSize=${pageSize}&search=${search}`,httpOptions)
  }
  getConsultantUsermanagementList(token,search,pageSize,pageIndex):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/auth/org/users?pageNumber=${pageIndex}&pageSize=${pageSize}&search=${search}`,httpOptions)
  }
  getAvailableTabsforConsultantUsers(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/auth/group/policies`,httpOptions)
  }

  getExistingConsltantUserDetails(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/auth/org/user?uuid=${data}`,httpOptions)
  }

  createUserForConsultant(token,formData):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/register/org/user`,formData,httpOptions)
  }

  modifyUserForConsultant(token,formData):Observable<any>{
    console.log(formData)
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.put<any>(`${this.apiUrl}/auth/org/user`,formData,httpOptions)
  }

  getCurrentUniversityData(token,search,pageSize,pageIndex):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     let searchResult=search!==''?`&search=${search}`:'';
    return this.http.get<any>(`${this.apiUrl}/txn/university/fetch?pageNumber=${pageIndex}&pageSize=${pageSize}${searchResult}`,httpOptions)
  }
  confirmUniversityPayments(token,txnId){
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.patch<any>(`${this.apiUrl}/txn/university/payment/confirm?txnId=${txnId}&isPaymentCompleted=true`,{},httpOptions)
  }



  lastUsedCurrency(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/lastUsedCurrency`,httpOptions)
  }
  // start the coding of geting the reset token for the reset password
  getResettoken(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/app/auth/resetToken`,token,httpOptions)
  }
  // end the coding of geting the reset token for the reset password
  QuotaionRates(token,youramount):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      }),
      // add the timeout code of parent environment file
      timeout: environment.timeout
      // end the timeout code of parent environment file
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/currency/allCurrenciesRates?youramount=${youramount}`,httpOptions)
  }
  QuotaionRatesByConsultant(token,youramount,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      }),
      // add the timeout code of parent environment file
      timeout: environment.timeout
      // end the timeout code of parent environment file
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/currency/allCurrenciesRates?youramount=${youramount}&consultantId=${id}`,httpOptions)
  }
  quotationListing(page:number, size:number, token: string, code?:string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     var options = '';

     if(page !== undefined) {
        options = `?pageIndex=${page}`
     }

     if(size !== undefined) {
      if(options) {
        options = options + `&pageLimit=${size}`;
      }else {
        options = `&pageLimit=${size}`;
      }
     }

     if( code ) {
      options = `?code=${code}`;
     }

     return this.http.get<any>(`${this.apiUrl}/txn/quotation/allQuotations${options}`,httpOptions);
  }
  quotationHistoryListing(page:number, size:number, token: string, code?:string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     var options = '';

     if(page !== undefined) {
        options = `?pageIndex=${page}`
     }

     if(size !== undefined) {
      if(options) {
        options = options + `&pageLimit=${size}`;
      }else {
        options = `&pageLimit=${size}`;
      }
     }

     if( code ) {
      options = `?code=${code}`;
     }

     return this.http.get<any>(`${this.apiUrl}/txn/quotation/allQuotations/history${options}`,httpOptions);
  }
  // start the define of api integration of add consultant and listing of consultant
  consultantListing(token,pageNumber,pageSize,searchValue):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/user/consultants?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${searchValue}`,httpOptions)
  }
  Searchconsultant(token,search):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/consultant/search?name=${search}`,httpOptions)
  }
  currencyFlag(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/currency/flag`,httpOptions)
  }
  // end the define of api integration of add consultant and listing of consultant
  fetchQuotationbyId(token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/quotation/${id}`,httpOptions)
  }
  makeAreaManager(token,email):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/user/consultant/update?email=${email}`,httpOptions)
  }
  addQuotation(token,body: any):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/quotation/new`,body, httpOptions)
  }
  addCrmOnboarding(token,body: any):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/auth/register/crm`,body, httpOptions)
  }
  saveRates(token, body: any):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/currency/exchangeRate`,body, httpOptions)
  }
  // start the define of api integration of update the margin of rate for consultant
  udpatemarginForConsultant(token, body: any,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/margin/consultant`,body, httpOptions)
  }
  // end the define of api integration of update the margin of rate for consultant
  getCurrency(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/currency/allCurrencies`,httpOptions)
  }
  // start the define of api integration of generate password for consultant
  emailHit(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/auth/consultant/mail?uuid=${data}`,httpOptions)
  }
// end the define of api integration of generate password for consultant

  getFinalCharnges(token,fromCurrency:String, fromCurrencyAmount:number, code?:String,fundId?:number,platform?:string):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     let coupon  = code?`&code=${code}`:``;
     let fundIdParam = fundId !== undefined ? `&sourceId=${fundId}` : '';
     let channelcode='';
     if(platform){
      channelcode=`&platform=${platform}`;
     }
    return this.http.get<any>(`${this.gatewayUrl}/txn/currency/finalCharges?currency=${fromCurrency}&amount=${fromCurrencyAmount}${coupon}${fundIdParam}${channelcode}`,httpOptions)
  }
  getUnivCode(token,code):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/university/global/getcode/url/${code}`,httpOptions)
  }
  getFinalCharngesConsultant(token,fromCurrency:String, fromCurrencyAmount:number,rate):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/currency/finalChargesConsultant?currency=${fromCurrency}&amount=${fromCurrencyAmount}&customerRate=${rate}`,httpOptions)
  }
  getFinalCharngesConsultantLive(token,fromCurrency:String, fromCurrencyAmount:number,rate,fundId:number,nostro?:number,bankProcessingFee?:number,coupn?:string):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     let cnostro  = nostro>=0?`&nostro=${nostro}`:``;
     let cbankProcessingFee  = bankProcessingFee>=0?`&bankProcessingFee=${bankProcessingFee}`:``;
     let finalCoupon = (coupn !== '' && coupn !== undefined && coupn !== null) ? `&code=${coupn}` : '';

    return this.http.get<any>(`${this.gatewayUrl}/txn/currency/consultant/finalCharges?currency=${fromCurrency}&amount=${fromCurrencyAmount}&customerRate=${rate}&sourceId=${fundId}${cnostro}${cbankProcessingFee}${finalCoupon}`,httpOptions)
  }
  getFinalCharngesConsultantLiveByConsultant(token,fromCurrency:String, fromCurrencyAmount:number,rate,id,fundId:number,nostro?:number,bankProcessingFee?:number):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     let cnostro  = nostro>=0?`&nostro=${nostro}`:``;
     let cbankProcessingFee  = bankProcessingFee>=0?`&bankProcessingFee=${bankProcessingFee}`:``;
    return this.http.get<any>(`${this.gatewayUrl}/txn/currency/consultant/finalCharges?currency=${fromCurrency}&amount=${fromCurrencyAmount}&customerRate=${rate}&consultantId=${id}&sourceId=${fundId}${cnostro}${cbankProcessingFee}`,httpOptions)
  }
  
  storeServiceFeeConfig(data, token):  Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/serviceFee`,data,httpOptions)
  }

  getServiceFeeConfig(data, token): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/serviceFee?currency=${data.currency}&amount=${data.amount}`,httpOptions)
  }
  getDashboardBlock(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/report/dashboard/summary?startDate=${data.startDate}&endDate=${data.endDate}`,httpOptions)
  }
  getDashboardStepDetails(today,lastMonth,token,page,size,status,channel,stage):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/report/dashboard/details?startDate=${lastMonth}&endDate=${today}&pageNumber=${page}&pageSize=${size}&status=${status}&channel=${channel}&stage=${stage}`,httpOptions)
  }
  getCrmListing(token,page,size,search):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/user/crm/getAll?pageNumber=${page}&pageSize=${size}&search=${search}`,httpOptions)
  }
  newtransactionStudent(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/new/student`,data,httpOptions)
  }

  newtransactionCanam(data,token):Observable<any>{
    console.log(data);
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/consultant/transaction/new`,data,httpOptions)
  }
  fileUpload(token, data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        //'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/kyc/txn/document/upload`,data,httpOptions)
  }
  studentInfo(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/${id}/update/UNIVERSITY/student`,data,httpOptions)
  }
  DocUpload(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/kyc/user/document/upload`,data,httpOptions)
  }

  studentKycUpload(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      }),
     };
    return this.http.post<any>(`${this.apiUrl}/kyc/user/document/upload/student`,data,httpOptions)
  }

  appDocDownload(token,appRef, docType, docSide):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };
     return this.http.get<any>(`${this.apiUrl}/kyc/txn/document/download?applicationId=${appRef}&documentName=${docType}`, httpOptions);
  }
  studentAppDocDownload(token,appRef, docType):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      }),
      responseType: "blob" as "json",
      observe: 'response' as "body"
     };
     return this.http.get<any>(`${this.apiUrl}/kyc/txn/document/download/student?applicationId=${appRef}&documentName=${docType}`, httpOptions);
  }
  downloadPaymentInstruction(token,appRef):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      }),
      responseType: "blob" as "json",
      observe: 'response' as "body"
     };
     return this.http.get<any>(`${this.apiUrl}/txn/consultant/payment/instruction?txnId=${appRef}`, httpOptions);
  }
  downloadQuotation(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      }),
      responseType: "blob" as "json",
      observe: 'response' as 'response'
     };
    //  let datafirst :any = { observe: 'response' };
    return this.http.get<any>(`${this.apiUrl}/txn/quotation/generatePdf?id=${data}`,httpOptions)
  }
  appKycDownload(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      }),
      responseType: "blob" as "json",
      observe: 'response' as 'response'
     };
    //  let datafirst :any = { observe: 'response' };
    return this.http.get<any>(`${this.apiUrl}/kyc/user/accounts/document/download/student?accountRef=${data.accountRef}&documentType=${data.documentType}&documentSide=${data.documentSide}`,httpOptions)
  }
  fetchA2FormStatus(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      }),
     };
    return this.http.get<any>(`${this.apiUrl}/txn/${data.accountRef}/crm`,httpOptions)
  }
  fileUploadForSenders(token, data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        //'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/kyc/user/accounts/document/upload/student`,data,httpOptions)
  }
  profileAccountSubmit(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/user`,data,httpOptions)
  }
  profileBankSubmit(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/user/account`,data,httpOptions)
  }
  profileBankFetch(token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/user/account?accountType=PAYEE`,httpOptions)
  }

  ////////-------------- Only For Student-------------------------///////////
  newtransaction(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/new`,data,httpOptions)
  }
  studentDetails(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/${id}/update/UNIVERSITY`,data,httpOptions)
  }
  studentPaymentInfo(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/${id}/update/PAYER_ACCOUNT_INFO/student`,data,httpOptions)
  }
  studentPayeeInfo(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/${id}/update/PAYEE/student`,data,httpOptions)
  }
  getAddress(token,pin):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/address/search?pincode=`+pin,httpOptions)
  }

  getAppDisplayDetails(token, id) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/`+id +'/student', httpOptions);
  }
  // start the define of api integration of consultant module
  getBasicDisplayDetails(token, id) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/consultant/info?uuid=${id}`, httpOptions);
  }
  getBankDisplayDetails(token, id) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/bank/info?id=${id}`, httpOptions);
  }
  getRatesConsultant(token, id) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.gatewayUrl}/txn/margin/consultant?uuid=${id}`, httpOptions);
  }
  updateConsultantInformation(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/consultant/info`,data,httpOptions)
  }
  updateConsultantBankInformation(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/bank/info`,data,httpOptions)
  }
  updatePassword(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/updatePinPass`,data,httpOptions)
  }
   // end the define of api integration of consultant module

   // start the coding of reset password API define for the reset password
  resetPassword(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/auth/resetPinPass`,data,httpOptions)
  }
  // end the coding of reset password API define for the reset password
  imageFetch(token,docuemntName,applicationid):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      }),
      responseType: "blob" as "json",
      observe: 'response' as "body"
     };
    return this.http.get<any>(`${this.apiUrl}/kyc/txn/document/download/student?applicationId=${applicationid}&documentName=${docuemntName}`,httpOptions)
  }
  studentdocUpload(token, data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        //'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/kyc/txn/document/upload/student`,data,httpOptions)
  }
  updateStudentInfo(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/${id}/update/UNIVERSITY/student`,data,httpOptions)
  }
  updateCancelTransaction(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        // 'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.patch<any>(`${this.apiUrl}/txn/cancel/txn`,data,httpOptions)
  }
  updateMarkpayment(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        // 'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/payment/completed`,data,httpOptions)
  }
  senderList(token,type,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/txn/account/${type}/student?id=`+id,httpOptions)
  }
  upateKycStaus(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        // 'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     const formData = this.convertToFormData(data);
    return this.http.post<any>(`${this.apiUrl}/txn/kyc/update`,formData,httpOptions)
  }
  createPaymentLink(token,data):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        // 'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     const formData = this.convertToFormData(data);
    return this.http.post<any>(`${this.apiUrl}/txn/payment/create`,formData,httpOptions)
  }
  private convertToFormData(data: any): FormData {
    const formData = new FormData();

    // Append key-value pairs from the data object to FormData
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formData.append(key, data[key]);
      }
    }

    return formData;
  }
  getIfsc(ifsc):Observable<any>{
    return this.http.get<any>(`https://ifsc.razorpay.com/${ifsc}`)
  }
  updateSenderInfo(data,token,id,accountType,type):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/account/update/${id}/${type}?accountRef=`+accountType,data,httpOptions)
  }
  FinalCharnges(token,txnId,fromCurrency:String, fromCurrencyAmount:number,rate,ibrRate, code?:String):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     let coupon  = code?`&code=${code}`:``;
    return this.http.get<any>(`${this.apiUrl}/txn/${txnId}/finalCharges/student?currency=${fromCurrency}&amount=${fromCurrencyAmount}&fxRate=${rate}&ibrRate=${ibrRate}${coupon}`,httpOptions)
  }
  confirmOrder(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/${id}/update/CONFIRMED/student`,data,httpOptions)
  }
  updateSenderBasicInformation(data,token,id,type):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/account/${id}/update/${type}`,data,httpOptions)
  }
  updateChanel(data,token):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.apiUrl}/txn/update/channel`,data,httpOptions)
  }
  collegeList(token,code):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/?countryCode=${code}&name=${''}&pageNumber=0&pageSize=20`,httpOptions)
  }
  universityaddressList(token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/university/${id}`,httpOptions)
  }
  confirmationSend(bankId,isconfirmed,dataa,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     https://test-aws.reeudo.com/api/v1/txn/RAPP-1675240596819/update/crm
    return this.http.post<any>(`${this.apiUrl}/txn/${id}/update/crm?isCrmConfirmed=`+isconfirmed+`&bankId=`+bankId,dataa,httpOptions)
  }
  getUserDisplayDetails(token, id) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/user/student?userRef=` + id, httpOptions);
  }
  selectedSender(token, accountRef, type) : Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.get<any>(`${this.apiUrl}/txn/account/info?accountRef=` + accountRef+`&accountType=`+type, httpOptions);
  }
  sendKycEmail(data, token): Observable<any> {
        const httpOptions = {
          headers: new HttpHeaders({ 
            'Content-Type': 'application/json',
            'Authorization' : 'Auth ' + token
          })
         };
        return this.http.post<any>(`${this.apiUrl}/notifcation/kyc`,data,httpOptions)
  }

  sentToTranscorp(token,dataT):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/assignViewer`,dataT,httpOptions)
  }

  paymentUpdate(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/${id}/update/CONFIRMED/student`,data,httpOptions)
  }

  kycApproveTrans(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/${id}/update/PAYER_KYC_APPROVED/student`,data,httpOptions)
  }

  paymentApproveTrans(data,token,id):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
    return this.http.post<any>(`${this.gatewayUrl}/txn/${id}/update/PAYER_PAYMENT_APPROVED/student`,data,httpOptions)
  }

  getAllDiscountCoupons(page:number, size:number, token: string, code?:string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     var options = '';

     if(page !== undefined) {
        options = `?pageNumber=${page}`
     }

     if(size !== undefined) {
      if(options) {
        options = options + `&pageSize=${size}`;
      }else {
        options = `&pageSize=${size}`;
      }
     }

     if( code ) {
      options = `?code=${code}`;
     }

     return this.http.get<any>(`${this.apiUrl}/txn/coupon${options}`,httpOptions);
  }
  getDiscountCouponById( token: string, code:string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     
     return this.http.get<any>(`${this.apiUrl}/txn/coupon?code=${code}`,httpOptions);
  }

  isValidCouponCode(code: string, token: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     return this.http.get<any>(`${this.apiUrl}/txn/coupon/${code}/isValid`,httpOptions);
  }
  searchFilter(value: string, token: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };
     
     return this.http.get<any>(`${this.apiUrl}/txn/consultant/search?username=${value}`,httpOptions);
  }

  addDiscountCoupon(data:any, token: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     return this.http.post<any>(`${this.apiUrl}/txn/coupon`,data,httpOptions);
  }
  

  checkForUserType(data:any, token: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     return this.http.get<any>(`${this.apiUrl}/consultant/check/areamanager?email=${data}`,httpOptions);
  }

   // start the define of api integration of add consultant module
  addConsultant(data:any, token: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     return this.http.post<any>(`${this.apiUrl}/auth/register/consultant`,data,httpOptions);
  }
// end the define of api integration of add consultant module

  disableDiscountCoupons(data:any, token: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     return this.http.post<any>(`${this.apiUrl}/txn/coupon/disable`,data,httpOptions);
  }

  // start the define of api integration of add consultant module
  statusConsultantChange(data:any, token: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     return this.http.post<any>(`${this.apiUrl}/consultant/status`,data,httpOptions);
  }
  statusCrmChange(data:any, token: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
          //  'Content-Type': 'multipart/form-data',
        'Authorization' : 'Auth ' + token
      })
     };

     return this.http.post<any>(`${this.apiUrl}/auth/user/status`,data,httpOptions);
  }
  statusConsultantUserCommunication(data:any, token: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization' : 'Auth ' + token
      })
     };

     return this.http.post<any>(`${this.apiUrl}/consultant/communication/status`,data,httpOptions);
  }
// end the define of api integration of add consultant module

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  date(date){
    let currentDate = new Date();
    let today= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    return today;
  }
}
