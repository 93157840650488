import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { MainService } from '../main.service';
import { NotifyService } from '../notify.service';
import $ from 'jquery';
@Component({
  selector: 'app-australia-flow',
  templateUrl: './australia-flow.component.html',
  styleUrls: ['./australia-flow.component.css']
})
export class AustraliaFlowComponent implements OnInit {
  Form: FormGroup;
  otp:boolean=false;
  constructor(public element:ElementRef, public router:Router,private renderer: Renderer2,public _dash:DashboardService,public notificationService:NotifyService,private formBuilder : FormBuilder,public main:MainService) {
    this.Form = this.formBuilder.group({
      email:new FormControl('', [Validators.email , Validators.required]),
      name:new FormControl('', [Validators.required]),
      otp:new FormControl(''),
    });
  }

  ngOnInit() {
  }
  submit(Form){
    // this.isSpinner =true;
    let reg_data ={
      "email": Form.email,
      "name": Form.name,
    }
    // this.otp = true;
    // if(Form.otp){
      window.open('https://www.commbank.com.au/Reeudo', '_blank');
      $('.close').click(); 
      this.otp = false;
      this.Form.reset();
    }
  //}

}
